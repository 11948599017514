import { createApp } from "vue";
import App from "./App.vue";
import "./assets/css/index.css";
import router from "./router";
import "./registerServiceWorker";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { vMaska } from "maska";
import snotify from "vue3-snotify";
import "vue3-snotify/style";

library.add(fas);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

//Antes
// createApp(App).component("fa", FontAwesomeIcon).use(router).mount("#app");

//Depois
const app = createApp(App);
app.component("fas", FontAwesomeIcon);
app.use(router);
app.use(pinia);
app.use(snotify);
app.directive("maska", vMaska);
app.mount("#app");
