import axios from "axios";
import { defineStore } from "pinia";

const url = process.env.VUE_APP_API_URL;

let url_api = url;

export const useCoursesStore = defineStore("courses", {
  state: () => ({
    id: null,
    id_responsavel: null,
    name_responsavel: null,
    id_cliente: null,
    razaoSocial: null,
    nome_course: null,
    nr: null,
    it: null,
    grupo: null,
    online: null,
    dt_curso: null,
    dt_vencimento: null,
  }),
  actions: {
    setCourseIdDetails(res) {
      //Busca do banco de dados
      // console.log("setcourseIdDetails", res.data);
      this.$state.id = res.data.id;
      this.$state.nome_course = res.data.nome_course;
      this.$state.nr = res.data.nr;
      this.$state.it = res.data.it;
      this.$state.grupo = res.data.grupo;
      this.$state.online = res.data.online;
      this.$state.dt_vencimento = res.data.dt_vencimento;
      this.$state.dt_curso = res.data.dt_curso;
      this.$state.id_responsavel = res.data.id_responsavel;
      this.$state.name_responsavel = res.data.user.name;
      this.$state.id_cliente = res.data.id_cliente;
      this.$state.razaoSocial = res.data.razaoSocial;
    },

    async fetchByIdCourse(id) {
      let res = await axios.get(url_api + "/api/course/" + id);
      // console.log(res.data.courses);
      this.$state.id = res.data.course.id;
      this.$state.nome_course = res.data.course.nome_course;
      this.$state.nr = res.data.course.nr;
      this.$state.it = res.data.course.it;
      this.$state.online = res.data.course.online;
      this.$state.grupo = res.data.course.grupo;
      this.$state.dt_vencimento = res.data.course.dt_vencimento;
      this.$state.dt_curso = res.data.course.dt_curso;
      if (res.data.course.user) {
        this.$state.id_responsavel = res.data.course.id_responsavel;
        this.$state.name_responsavel = res.data.course.user.name;
      } else {
        this.$state.id_responsavel = null;
        this.$state.name_responsavel = null;
      }
      if (res.data.course.company) {
        this.$state.id_cliente = res.data.course.id_cliente;
        this.$state.razaoSocial = res.data.course.company.razaoSocial;
      } else {
        this.$state.id_cliente = null;
        this.$state.razaoSocial = null;
      }
    },

    clearIdcourse() {
      this.$state.id = null;
      this.$state.nome_course = null;
      this.$state.nr = null;
      this.$state.it = null;
      this.$state.online = null;
      this.$state.grupo = null;
      this.$state.dt_vencimento = null;
      this.$state.dt_curso = null;
      this.$state.id_responsavel = null;
      this.$state.name_responsavel = null;
      this.$state.id_cliente = null;
      this.$state.razaoSocial = null;
    },
  },
  persist: true,
});
