<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12"></div>
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <div class="flex flex-wrap mt-4">
          <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
            >
              <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                  <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-base text-blueGray-700">
                      FATURAMENTO
                      <tr>
                        <td>
                          <RouterLinkButton
                            btnText="Entrada(Compra)"
                            color="sky"
                            tipo="fas"
                            icone="plus"
                            url="/solicitacaoentradaCproduto"
                          />
                        </td>
                        <td>
                          <RouterLinkButton
                            btnText="Saída(Venda)"
                            color="sky"
                            tipo="fas"
                            icone="minus"
                            url="/solicitacaosaidaCproduto"
                          />
                        </td>
                        <td>
                          <RouterLinkButton
                            btnText="Voltar"
                            color="sky"
                            tipo="fas"
                            icone="arrow-left"
                            url="dashboard"
                          />
                        </td>
                      </tr>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="bg-green-500 w-full h-1"></div>
              <div class="text-gray-900 text-xl">Entrada(Compra)</div>
              <div>
                <button
                  class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  v-on:click="toggleModalentrada()"
                >
                  Localizar solicitação !
                </button>
                <div
                  v-if="showModalentrada"
                  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                >
                  <div class="relative w-auto my-6 mx-auto max-w-3xl">
                    <!--content-->
                    <div
                      class="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                    >
                      <!--header-->
                      <div
                        class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                      >
                        <h3 class="text-3xl font-semibold">
                          Solicitação compra(entrada)
                        </h3>
                        <button
                          class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          v-on:click="toggleModalentrada()"
                        >
                          <span
                            class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                          >
                            ×
                          </span>
                        </button>
                      </div>
                      <!--body-->
                      <div class="relative p-6 flex-auto">
                        <div class="flex mt-4 mb-8">
                          <div class="w-full md:w-1/2 px-3">
                            <TextInput
                              label="Digitar o código da solicitação de compra(entrada)"
                              placeholder="Pesquisar..."
                              v-model:input="searchEntrada"
                              inputType="text"
                            />
                          </div>
                          <div class="w-full md:w-1/2 px-3">
                            <form
                              @submit.prevent="onSubmit"
                              @click="getIdentradaList"
                            >
                              <LoadingButton
                                :isLoading="isLoading"
                                class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                              >
                                Pesquisar
                              </LoadingButton>
                            </form>
                          </div>
                          <div class="mx-auto py-4">
                            <table class="table-auto text-xs">
                              <thead>
                                <tr class="text-gray-600 bg-gray-300">
                                  <th class="border px-4 py-2">Id</th>
                                  <th class="border px-4 py-2">Emissão</th>
                                  <th class="border px-4 py-2">Valor</th>
                                  <th class="border px-4 py-2">Ação</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="text-gray-500 bg-gray-100"
                                  v-for="listEntrada in listEntradas"
                                  :key="listEntrada"
                                >
                                  <td>{{ listEntrada.id }}</td>
                                  <td
                                    v-if="listEntrada.dt_emissao !== null"
                                    class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                                  >
                                    {{ formatDate(listEntrada.dt_emissao) }}
                                  </td>
                                  <td>{{ listEntrada.valorTotalPedido }}</td>
                                  <td
                                    class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                  >
                                    <router-link
                                      :to="
                                        'solicitacaoentradaEproduto/' +
                                        listEntrada.id
                                      "
                                      class="underline text-blue-500 hover:text-blue-600"
                                    >
                                      <fas :icon="['fas', 'pen']" />
                                    </router-link>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="flex items-center justify-center p-2">
                              <v-pagination
                                v-model="pageEntradas"
                                :pages="pageCountEntradas"
                                :range-size="1"
                                active-color="#337aff"
                                @update:modelValue="listEntradas"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <vue-snotify />
                      <!--footer-->
                      <div
                        class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                      >
                        <button
                          class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          v-on:click="toggleModalentrada()"
                        >
                          Sair
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showModal"
                  class="opacity-25 fixed inset-0 z-40 bg-black"
                ></div>
              </div>

              <div class="block w-full overflow-x-auto">
                <table
                  class="items-center w-full bg-transparent border-collapse"
                >
                  <thead>
                    <tr class="bg-gray-300">
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Ação
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      ></th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Solicitação
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Empresa
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Fornecedor
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Emissão
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Retirada
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Entrega
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Situação
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="listESocitacao in listEntradaSocitacao"
                      :key="listESocitacao.id"
                    >
                      <td
                        class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        <router-link
                          :to="
                            'solicitacaoentradaEproduto/' + listESocitacao.id
                          "
                          class="underline text-blue-500 hover:text-blue-600"
                        >
                          <fas :icon="['fas', 'pen']" />
                        </router-link>
                      </td>
                      <td
                        v-if="listESocitacao.situacao !== 'FINALIZADO'"
                        class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        <RouterLinkButton
                          btnText=""
                          color="red"
                          tipo="fas"
                          icone="trash"
                          url=""
                          @click="deleteEsolitacao(listESocitacao.id)"
                        />
                      </td>
                      <td
                        v-if="listESocitacao.situacao == 'FINALIZADO'"
                        class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        <RouterLinkButton
                          btnText=""
                          color="green"
                          tipo="fas"
                          icone="boxes-packing"
                          url=""
                          @click="updateEsolitacao(listESocitacao.id)"
                        />
                      </td>
                      <th
                        v-if="listESocitacao.id !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listESocitacao.id }}
                      </th>
                      <th
                        v-if="listESocitacao.company !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listESocitacao.company.nomeFantasia }}
                      </th>
                      <td
                        v-else
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      ></td>
                      <th
                        v-if="listESocitacao.supplier !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listESocitacao.supplier.nomeFantasia }}
                      </th>
                      <td
                        v-else
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      ></td>
                      <td
                        v-if="listESocitacao.dt_emissao !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ formatDate(listESocitacao.dt_emissao) }}
                      </td>
                      <td
                        v-else
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      ></td>
                      <td
                        v-if="listESocitacao.dt_retirada !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ formatDate(listESocitacao.dt_retirada) }}
                      </td>
                      <td
                        v-else
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      ></td>
                      <td
                        v-if="listESocitacao.dt_entrega !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ formatDate(listESocitacao.dt_entrega) }}
                      </td>
                      <td
                        v-else
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      ></td>
                      <td
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listESocitacao.situacao }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="flex items-center justify-center p-2">
                  <v-pagination
                    v-model="page"
                    :pages="pageCount_entrada"
                    :range-size="1"
                    active-color="#337aff"
                    @update:modelValue="getEntradaSolicitacaoList"
                  />
                </div>
              </div>
              <div class="bg-green-500 w-full h-1"></div>
              <div class="text-gray-900 text-xl">Saída(Venda)</div>
              <div>
                <button
                  class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  v-on:click="toggleModalsaida()"
                >
                  Localizar solicitação !
                </button>
                <div
                  v-if="showModalsaida"
                  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
                >
                  <div class="relative w-auto my-6 mx-auto max-w-3xl">
                    <!--content-->
                    <div
                      class="border-4 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                    >
                      <!--header-->
                      <div
                        class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                      >
                        <h3 class="text-3xl font-semibold">
                          Solicitação venda(saída)
                        </h3>
                        <button
                          class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                          v-on:click="toggleModalsaida()"
                        >
                          <span
                            class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                          >
                            ×
                          </span>
                        </button>
                      </div>
                      <!--body-->
                      <div class="relative p-6 flex-auto">
                        <div class="flex mt-4 mb-8">
                          <div class="w-full md:w-1/2 px-3">
                            <TextInput
                              label="Digitar o código da solicitação de venda(saída)"
                              placeholder="Pesquisar..."
                              v-model:input="searchSaida"
                              inputType="text"
                            />
                          </div>
                          <div class="w-full md:w-1/2 px-3">
                            <form
                              @submit.prevent="onSubmit"
                              @click="getIdsaidaList"
                            >
                              <LoadingButton
                                :isLoading="isLoading"
                                class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                              >
                                Pesquisar
                              </LoadingButton>
                            </form>
                          </div>
                          <div class="mx-auto py-4">
                            <table class="table-auto text-xs">
                              <thead>
                                <tr class="text-gray-600 bg-gray-300">
                                  <th class="border px-4 py-2">Id</th>
                                  <th class="border px-4 py-2">Emissão</th>
                                  <th class="border px-4 py-2">Valor</th>
                                  <th class="border px-4 py-2">Ação</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="text-gray-500 bg-gray-100"
                                  v-for="listSaida in listSaidas"
                                  :key="listSaida"
                                >
                                  <td>{{ listSaida.id }}</td>
                                  <td
                                    v-if="listSaida.dt_emissao !== null"
                                    class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                                  >
                                    {{ formatDate(listSaida.dt_emissao) }}
                                  </td>
                                  <td>{{ listSaida.valorTotalPedido }}</td>
                                  <td
                                    class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                                  >
                                    <router-link
                                      :to="
                                        'solicitacaosaidaEproduto/' +
                                        listSaida.id
                                      "
                                      class="underline text-blue-500 hover:text-blue-600"
                                    >
                                      <fas :icon="['fas', 'pen']" />
                                    </router-link>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="flex items-center justify-center p-2">
                              <v-pagination
                                v-model="pageSaidas"
                                :pages="pageCountSaidas"
                                :range-size="1"
                                active-color="#337aff"
                                @update:modelValue="listSaidas"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <vue-snotify />
                      <!--footer-->
                      <div
                        class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                      >
                        <button
                          class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          v-on:click="toggleModalsaida()"
                        >
                          Sair
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showModal"
                  class="opacity-25 fixed inset-0 z-40 bg-black"
                ></div>
              </div>
              <div class="block w-full overflow-x-auto">
                <table
                  class="items-center w-full bg-transparent border-collapse"
                >
                  <thead>
                    <tr class="bg-gray-300">
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Ação
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Solicitação
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Empresa
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Emissão
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                      >
                        Situação
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="listSSocitacao in listSaidaSocitacao"
                      :key="listSSocitacao.id"
                    >
                      <td
                        class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        <router-link
                          :to="'solicitacaosaidaEproduto/' + listSSocitacao.id"
                          class="underline text-blue-500 hover:text-blue-600"
                        >
                          <fas :icon="['fas', 'pen']" />
                        </router-link>
                      </td>
                      <th
                        v-if="listSSocitacao.id !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listSSocitacao.id }}
                      </th>
                      <th
                        v-if="listSSocitacao.company.nomeFantasia !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listSSocitacao.company.nomeFantasia }}
                      </th>
                      <td
                        v-else
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      ></td>
                      <td
                        v-if="listSSocitacao.dt_emissao !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ formatDate(listSSocitacao.dt_emissao) }}
                      </td>
                      <td
                        v-else
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      ></td>
                      <td
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                      >
                        {{ listSSocitacao.situacao }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="flex items-center justify-center p-2">
                  <v-pagination
                    v-model="page"
                    :pages="pageCount_saida"
                    :range-size="1"
                    active-color="#337aff"
                    @update:modelValue="getSaidaSolicitacaoList"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import LoadingButton from "@/components/global/LoadingButton.vue";
import VPagination from "@hennge/vue3-pagination";
import V3Pagination from "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useUserStore } from "@/store/user-store";

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    SubmitFormButton,
    VPagination,
    V3Pagination,
    TextInput,
    LoadingButton,
  },

  data() {
    const userStore = useUserStore();
    const url = process.env.VUE_APP_API_URL;

    let hover = ref(false);
    let showModalentrada = ref(false);
    let showModalsaida = ref(false);

    let listEntradaSocitacao = ref([]);

    let searchSaida = ref(null);
    let listSaidas = ref(null);
    let pageSaidas = ref(1);
    let pageCountSaidas = ref(null);

    let searchEntrada = ref(null);
    let listEntradas = ref(null);
    let pageEntradas = ref(1);
    let pageCountEntradas = ref(null);

    return {
      date: new Date().getFullYear(),
      administrador: userStore.administrador,
      id_empresa: userStore.id_empresa,
      url_api: url,
      hover: hover,
      showModalentrada: showModalentrada,
      searchEntrada: searchEntrada,
      listEntradas: listEntradas,
      pageEntradas: pageEntradas,
      pageCountEntradas: pageCountEntradas,
      showModalsaida: showModalsaida,
      searchSaida: searchSaida,
      listSaidas: listSaidas,
      pageSaidas: pageSaidas,
      pageCountSaidas: pageCountSaidas,
      listEntradaSocitacao: listEntradaSocitacao,
      page_entrada: ref(1),
      pageCount_entrada: ref(null),
      listSaidaSocitacao: ref([]),
      page_saida: ref(1),
      pageCount_saida: ref(null),
      isLoading: ref(false),
      isLoadingEntry: ref(false),
    };
  },
  methods: {
    getEntradaSolicitacaoList() {
      // Make a GET request to the API
      if (this.administrador == 1) {
        axios
          .get(this.url_api + "/api/salesorderentry?page=" + this.page_entrada)
          .then((resEntrada) => {
            // console.log(resEntrada.data)
            this.pageCount_entrada = resEntrada.data.page_countEntrada;
            this.listEntradaSocitacao = resEntrada.data.paginateEntrada.data;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error getEntradaSolicitacaoList:", error);
          });
      } else {
        axios
          .get(
            this.url_api +
              "/api/salesorderentrylist/" +
              this.id_empresa +
              "?page=" +
              this.page_entrada
          )
          .then((resEntrada) => {
            // console.log(resEntrada.data)
            this.pageCount_entrada = resEntrada.data.page_countEntrada;
            this.listEntradaSocitacao = resEntrada.data.paginateEntrada.data;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error getEntradaSolicitacaoList:", error);
          });
      }
    },
    getSaidaSolicitacaoList() {
      if (this.administrador == 1) {
        axios
          .get(this.url_api + "/api/salesorder?page=" + this.page_saida)
          .then((resSaida) => {
            console.log(resSaida.data);
            this.pageCount_saida = resSaida.data.page_count;
            this.listSaidaSocitacao = resSaida.data.paginate.data;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error getSaidaSolicitacaoList:", error);
          });
      } else {
        // axios.get(this.url_api + '/api/salesorder?page=' + this.page_saida)
        axios
          .get(
            this.url_api +
              "/api/salesorderlist/" +
              this.id_empresa +
              "?page=" +
              this.page
          )
          .then((resSaida) => {
            // console.log(resSaida.data)
            this.pageCount_saida = resSaida.data.page_countSaida;
            this.listSaidaSocitacao = resSaida.data.paginateSaida.data;
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error getSaidaSolicitacaoList:", error);
          });
      }
    },
    getIdsaidaList() {
      // Make a GET request to the API
      axios
        .get(
          this.url_api +
            "/api/salesordersearchId/" +
            this.searchSaida +
            "?page=" +
            this.pageSaidas
        )
        .then((response) => {
          // Handle the successful response
          //console.log(response.data);
          this.pageCountSaidas = response.data.page_count;
          this.listSaidas = response.data.paginate.data;
        })
        .catch((error) => {
          // Handle any errors
          //console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getIdentradaList() {
      // Make a GET request to the API
      axios
        .get(
          this.url_api +
            "/api/salesorderentrysearchId/" +
            this.searchEntrada +
            "?page=" +
            this.pageEntradas
        )
        .then((response) => {
          // Handle the successful response
          console.log(response.data);
          this.pageCountEntradas = response.data.page_count;
          this.listEntradas = response.data.paginate.data;
        })
        .catch((error) => {
          // Handle any errors
          //console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    toggleModalentrada() {
      this.showModalentrada = !this.showModalentrada;
    },
    toggleModalsaida() {
      this.showModalsaida = !this.showModalsaida;
    },
    toggleModalentrada() {
      this.showModalentrada = !this.showModalentrada;
    },
    onPageChange() {
      this.getEntradaSolicitacaoList();
      this.getSaidaSolicitacaoList();
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(date);
    },
    onSubmit() {
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 1000);
    },
    deleteEsolitacao(event) {
      // id_solicitacao = event.id

      // console.log(event)

      axios
        .delete(this.url_api + "/api/salesorderentry/" + event)
        .then((resDelete) => {
          // console.log(resDelete.data);
          this.getEntradaSolicitacaoList();
          this.$snotify.warning("deletou : " + resDelete.data);
        })
        .catch((error) => {
          // Handle any errors
          this.getEntradaSolicitacaoList();
          this.$snotify.warning("Solicitação já deletado : ");
          console.error("Error deleteEsolitacao():", error);
        });
    },
    updateEsolitacao(event) {
      this.$snotify.info("Mensagem : " + event.id);

      try {
        id_solicitacao = event.id;

        let resEstoque = axios.get(
          this.url_api + "/api/salesorderElessstock/" + id_solicitacao
        );

        // console.log(resEstoque.data)

        this.$snotify.info("Mensagem : " + resEstoque.data);
      } catch (err) {
        console.log(err);
      }
    },
  },
  onPageChange() {
    this.getIdsaidaList();
  },
  mounted() {
    this.getEntradaSolicitacaoList();
    this.getSaidaSolicitacaoList();
  },
  onSubmit() {
    this.isLoading = true;
    setTimeout(() => (this.isLoading = false), 1000);
  },
};
</script>
