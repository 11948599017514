<template>
  <div>
    <main>
      <section class="absolute w-full h-full">
        <div class="absolute top-0 w-full h-full bg-center bg-cover" >
          <img src="@/assets/img/logoempresa.jpg" />
        </div>
        <div class="container mx-auto px-4 w-full h-full">
          <div class="flex conte  nt-center items-center justify-center h-full">
            <div class="w-full lg:w-4/12 px-4">
              
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-0 shadow-lg rounded-lg bg-gray-300 border-0">
                
                <div class="rounded-t mb-0 px-6 py-6">
                  <hr class="mt-6 border-b-1 border-gray-400" />
                </div>
                
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <div class="text-gray-500 text-xs text-center mb-4 font-bold">
                    <label class="block text-gray-700 text-xl font-bold mb-2" for="grid-password">Fazer login
                      com suas credenciais
                    </label>
                  </div>
                  <form>
                    <div class="relative w-full mb-3">
                      <LoginTextInput label="Name" :labelColor="black" placeholder="Digitar o seu nome de login !"
                        v-model:input="login_name" inputType="text"
                        :error="errors.login_name ? errors.login_name[0] : ''" />
                    </div>
                    <div class="relative w-full mb-3">
                      <LoginTextInput label="Password" :labelColor="black" placeholder="Digitar a sua senha !"
                        v-model:input="password" inputType="password"
                        :error="errors.password ? errors.password[0] : ''" />
                    </div>
                    
                    <div class="text-center mt-6">
                      <form @submit.prevent="onSubmit" @click="login">
                        <LoadingButton :isLoading="isLoading" class="
                          block w-full
                          bg-green-500
                          text-white
                          rounded-sm
                          py-3 text-sm
                          tracking-wide
                          transform 
                          motion-safe:hover:-translate-y-1 
                          text-blue-700 
                          motion-safe:hover:scale-110 
                          transition 
                          ease-in-out">
                          Entrar
                        </LoadingButton>
                      </form>
                    </div>
                    <div>
                      <a class="lg:text-sky-600 lg:hover:text-sky-200 text-sky-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                            href="/"><i class="lg:text-sky-300 text-sky-500 far fa-file-alt text-lg leading-lg mr-2"></i>
                            Se não conseguir fazer login, entrar em contato com suporte!
                      </a>

                      <!-- Whatsapp -->
                      <button type="button" data-te-ripple-init data-te-ripple-color="light"
                          class="mb-2 inline-block rounded px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                          style="background-color: #128c7e">
                          <a href="https://wa.me/5511962422624">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="currentColor"
                                  viewBox="0 0 24 24">
                                  <path
                                      d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                              </svg>
                          </a>
                      </button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer-component></footer-component>
      </section>
    </main>
  </div>
</template>

<script setup>

import { ref } from 'vue';
import axios from 'axios';
import { useUserStore } from '@/store/user-store'
import { useRouter } from 'vue-router'
import LoginTextInput from "@/components/global/login/LoginTextInput.vue";
import NavbarComponent from "@/components/global/login/LoginTopNavigation.vue";
import FooterComponent from "@/components/global/login/LoginFooter.vue";
import LoadingButton from '@/components/global/LoadingButton.vue';

const router = useRouter()
const userStore = useUserStore()
const isLoading = ref(false);
const url = process.env.VUE_APP_API_URL;

let url_api = url
let errors = ref([]);
let login_name = ref(null);
let password = ref(null);

const login = async () => {

  try {

    let res = await axios.post(url_api + "/api/login", {
      login_name: login_name.value,
      password: password.value
    })

    userStore.setUserDetails(res)

    //Rota
    //router.push('/dasboard' + userStore.id)
    router.push("dashboard");


  } catch (err) {

    // console.log(err);

    errors.value = err.response.data.errors

  }

};

function onSubmit() {
  isLoading.value = true;
  setTimeout(() => isLoading.value = false, 1000)
}

</script> 


