import axios from "axios";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    id: null,
    token: null,
    full_name: null,
    login_name: null,
    celular: null,
    cargo: null,
    email: null,
    rg: null,
    cpf: null,
    password: null,
    location: null,
    image: null,
    description: null,
    id_empresa: null,
    administrador: null,
    usuario: null,
    deleted_at: null,
  }),
  actions: {
    // async setUserDetails(res) {
    setUserDetails(res) {
      //Busca do banco de dados
      // console.log("setUserDetails", res.data);
      this.$state.id = res.data.user.id;
      this.$state.token = res.data.token;
      this.$state.full_name = res.data.user.full_name;
      this.$state.login_name = res.data.user.login_name;
      this.$state.celular = res.data.user.celular;
      this.$state.cargo = res.data.user.cargo;
      this.$state.email = res.data.user.email;
      this.$state.rg = res.data.user.rg;
      this.$state.cpf = res.data.user.cpf;
      this.$state.password = res.data.user.password;
      this.$state.location = res.data.user.location;
      this.$state.description = res.data.user.description;
      // this.$state.image = res.data.user.image;
      this.$state.id_empresa = res.data.user.id_empresa;
      this.$state.administrador = res.data.user.administrador;
      this.$state.usuario = res.data.user.usuario;
      this.$state.deleted_at = res.data.user.deleted_at;
    },

    async fetchUser() {
      let res = await axios.get("api/users" + this.$state.id);

      this.$state.id = res.data.user.id;
      this.$state.full_name = res.data.user.full_name;
      this.$state.login_name = res.data.user.login_name;
      this.$state.celular = res.data.user.celular;
      this.$state.cargo = res.data.user.cargo;
      this.$state.email = res.data.user.email;
      this.$state.rg = res.data.user.rg;
      this.$state.cpf = res.data.user.cpf;
      this.$state.password = res.data.user.password;
      this.$state.location = res.data.user.location;
      this.$state.description = res.data.user.description;
      // if (res.data.user.image) {
      //   this.$state.image =
      //     process.env.VUE_APP_API_URL + "images/users/" + res.data.user.image;
      // } else {
      //   this.$state.image =
      //     process.env.VUE_APP_API_URL + "DefaultUserAvatar.png";
      // }
      this.$state.id_empresa = res.data.user.id_empresa;
      this.$state.administrador = res.data.user.administrador;
      this.$state.usuario = res.data.user.usuario;
      this.$state.deleted_at = res.data.user.deleted_at;
    },

    userImage(image) {
      return process.env.VUE_APP_API_URL + "images/users/" + image;
    },

    clearUser() {
      this.$state.id = null;
      this.$state.token = null;
      this.$state.full_name = null;
      this.$state.celular = null;
      this.$state.login_name = null;
      this.$state.cargo = null;
      this.$state.email = null;
      this.$state.rg = null;
      this.$state.cpf = null;
      this.$state.password = null;
      this.$state.location = null;
      this.$state.description = null;
      // this.$state.image = null;
      this.$state.id_empresa = null;
      this.$state.administrador = null;
      this.$state.usuario = null;
      this.$state.deleted_at = null;
    },
  },
  persist: true,
});
