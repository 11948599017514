<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div class="
              container 
              mx-auto 
              my-12
              h-auto
              flex 
              flex-wrap 
              justify-between 
              items-start 
              text-sm 
              p-8 
              pt-8 
              pb-4 
              box-border 
              border-4 
              border-gray-400 
              bg-gray-100">
            <a class="text-2xl text-center text-black font-bold">Admin: Cadastrar treinamento</a>
            <RouterLinkButton btnText="" color="sky" tipo="fas" icone="arrow-left" url='/admlistatreinamento' />
            <div class="bg-green-500 w-full h-1"></div>
            <a class="text-sky-400 font-bold">Localidade : {{ id_cliente }}-{{ razaoSocial }}</a>
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" v-on:click="toggleModal()">
                Escolha a igreja que pertence o treinamento !
              </button>
              <div v-if="showModal"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 class="text-3xl font-semibold">
                        Igreja
                      </h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModal()">
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput label="Digitar o nome da igreja" placeholder="Pesquisar..."
                            v-model:input="searchCompany" inputType="text" />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form @submit.prevent="onSubmit" @click="getCompanyList">
                            <LoadingButton :isLoading="isLoading" class="
                              block w-full
                              bg-green-500
                              text-white
                              rounded-sm
                              py-3 text-sm
                              tracking-wide
                              transform 
                              motion-safe:hover:-translate-y-1 
                              text-blue-700 
                              motion-safe:hover:scale-110 
                              transition 
                              ease-in-out">
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Igreja</th>
                                <th class="border px-4 py-2"></th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="text-gray-500 bg-gray-100" v-for="listCompany in listCompanies"
                                :key="listCompany">
                                <td>{{ listCompany.id }}</td>
                                <td>{{ listCompany.razaoSocial }}</td>
                                <td>{{ listCompany.nomeFantasia }}</td>
                                <td>
                                  <div class="
                                    block w-full
                                    bg-green-500
                                    text-white
                                    rounded-sm
                                    py-3 text-sm
                                    tracking-wide
                                    transform 
                                    motion-safe:hover:-translate-y-1 
                                    text-blue-700 
                                    motion-safe:hover:scale-110 
                                    transition 
                                    ease-in-out">
                                    <SubmitFormButton btnText="Escolha" @click="selectCompany(listCompany)" />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2 ">
                            <v-pagination v-model="pageCompanies" :pages="pageCountCompanies" :range-size="1"
                              active-color="#337aff" @register:modelValue="listCompanies" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button" v-on:click="toggleModal()">
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="text-sky-400">
              <TextInput label="Nome do voluntário" placeholder="Digitar o nome do voluntário !"
                v-model:input="nome_usuario" inputType="hidden"
                :error="errors.nome_usuario ? errors.nome_usuario[0] : ''" />
              {{ nome_usuario }}
            </div>
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" v-on:click="toggleModalVoluntary()">
                Escolha o voluntário que pertence o treinamento !
              </button>
              <div v-if="showModalVoluntary"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 class="text-3xl font-semibold">
                        Voluntário
                      </h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModalVoluntary()">
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput label="Digitar o nome do voluntário" placeholder="Pesquisar..."
                            v-model:input="searchVoluntary" inputType="text" />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form @submit.prevent="onSubmit" @click="getVoluntaryList">
                            <LoadingButton :isLoading="isLoading" class="
                              block w-full
                              bg-green-500
                              text-white
                              rounded-sm
                              py-3 text-sm
                              tracking-wide
                              transform 
                              motion-safe:hover:-translate-y-1 
                              text-blue-700 
                              motion-safe:hover:scale-110 
                              transition 
                              ease-in-out">
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Volunário</th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="text-gray-500 bg-gray-100" v-for="listVoluntary in listVoluntarys"
                                :key="listVoluntary">
                                <td>{{ listVoluntary.id }}</td>
                                <td>{{ listVoluntary.full_name }}</td>
                                <td>
                                  <div class="
                                    block w-full
                                    bg-green-500
                                    text-white
                                    rounded-sm
                                    py-3 text-sm
                                    tracking-wide
                                    transform 
                                    motion-safe:hover:-translate-y-1 
                                    text-blue-700 
                                    motion-safe:hover:scale-110 
                                    transition 
                                    ease-in-out">
                                    <SubmitFormButton btnText="Escolha" @click="selectVoluntary(listVoluntary)" />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2 ">
                            <v-pagination v-model="pageVoluntarys" :pages="pageCountVoluntarys" :range-size="1"
                              active-color="#337aff" @update:modelValue="listVoluntarys" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button" v-on:click="toggleModalVoluntary()">
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showModalVoluntary" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="text-sky-400">
              <TextInput label="Nome treinamento" placeholder="Digitar o nome do nome treinamento !"
                v-model:input="nome_course" inputType="hidden" :error="errors.nome_course ? errors.nome_course[0] : ''" />
              {{ nome_course }}
            </div>
            <div class="text-sky-400">
              <TextInput label="NR" placeholder="Digitar o NR !" v-model:input="nr" inputType="hidden" />
              {{ nr }}
            </div>
            <div class="text-sky-400">
              <TextInput label="IT" placeholder="Digitar o IT !" v-model:input="it" inputType="hidden" />
              {{ it }}
            </div>
            <div class="text-sky-400">
              <TextInput label="GRUPO" placeholder="Digitar o grupo !" v-model:input="grupo" inputType="hidden" />
              {{ grupo }}
            </div>
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" v-on:click="toggleModalCourse()">
                Escolha o Curso que pertence o treinamento !
              </button>
              <div v-if="showModalCourse"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 class="text-3xl font-semibold">
                        Curso
                      </h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModalCourse()">
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput label="Digitar o nome do curso" placeholder="Pesquisar..."
                            v-model:input="searchCourse" inputType="text" />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form @submit.prevent="onSubmit" @click="getCourseList">
                            <LoadingButton :isLoading="isLoading" class="
                              block w-full
                              bg-green-500
                              text-white
                              rounded-sm
                              py-3 text-sm
                              tracking-wide
                              transform 
                              motion-safe:hover:-translate-y-1 
                              text-blue-700 
                              motion-safe:hover:scale-110 
                              transition 
                              ease-in-out">
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Curso</th>
                                <th class="border px-4 py-2">NR</th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="text-gray-500 bg-gray-100" v-for="listCourse in listCourses" :key="listCourse">
                                <td>{{ listCourse.id }}</td>
                                <td>{{ listCourse.nome_course }}</td>
                                <td>{{ listCourse.nr }}</td>
                                <td>
                                  <div class="
                                    block w-full
                                    bg-green-500
                                    text-white
                                    rounded-sm
                                    py-3 text-sm
                                    tracking-wide
                                    transform 
                                    motion-safe:hover:-translate-y-1 
                                    text-blue-700 
                                    motion-safe:hover:scale-110 
                                    transition 
                                    ease-in-out">
                                    <SubmitFormButton btnText="Escolha" @click="selectCourse(listCourse)" />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2 ">
                            <v-pagination v-model="pageCourses" :pages="pageCountCourses" :range-size="1"
                              active-color="#337aff" @update:modelValue="listCourses" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button" v-on:click="toggleModalCourse()">
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showModalCourse" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </div>
            <div class="w-full md:w-full px-3">
              <div class="bg-green-500 w-full h-1"></div>
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none">
                  <div class=" w-full text-gray-900 md:w-full px-6">
                    <TextInput label="ONLINE/PRESENCIAL" placeholder="Digitar online ou presensial !"
                      v-model:input="online" inputType="text" />
                  </div>
                  <div class=" w-full text-gray-900 md:w-full px-6">
                    <TextInput label="APROVADO" placeholder="Digitar aprovado !" v-model:input="aprovado"
                      inputType="text" />
                  </div>
                  <div class="w-full text-gray-900 md:w-full px-6">
                    <TextInputDate label="DATA CURSO " placeholder="Digitar data curso !" v-model:input="dt_curso"
                      inputType="text" :error="errors.dt_curso ? errors.dt_curso[0] : ''" />
                    <div class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"></div>
                  </div>
                  <div class="w-full text-gray-900 md:w-full px-6">
                    <TextInputDate label="Vencimento" placeholder="Digitar data de vencimento !"
                      v-model:input="dt_vencimento" inputType="text"
                      :error="errors.dt_vencimento ? errors.dt_vencimento[0] : ''" />
                    <div class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="flex flex-wrap mt-8 mb-6">
              <form @submit.prevent="onSubmit" @click="register">
                <LoadingButton :isLoading="isLoading" class="
                  block w-full
                  bg-green-500
                  text-white
                  rounded-sm
                  py-3 text-sm
                  tracking-wide
                  transform 
                  motion-safe:hover:-translate-y-1 
                  text-blue-700 
                  motion-safe:hover:scale-110 
                  transition 
                  ease-in-out">
                  Alterar
                </LoadingButton>
              </form>
              <vue-snotify />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { ref } from 'vue';
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputCelular from "@/components/global/TextInputCelular.vue";
import TextInputRg from "@/components/global/TextInputRg.vue";
import TextInputCpf from "@/components/global/TextInputCpf.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import LoadingButton from '@/components/global/LoadingButton.vue';
import SubmitFormButton from '@/components/global/SubmitFormButton.vue';

import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '@/store/user-store';
import { useTrainingsStore } from '@/store/trainings-store';

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    LoadingButton,
    TextInput,
    SubmitFormButton,
    TextInputCelular,
    TextInputRg,
    TextInputCpf,
    TextInputDate

  },
  name: "regular-modal",
  data() {

    const userStore = useUserStore();
    const useTrainingStore = useTrainingsStore();
    const route = useRoute()
    const router = useRouter()

    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);
    let showModal = ref(false);
    let showModalVoluntary = ref(false);

    let searchCompany = ref(null)
    let listCompanies = ref(null)
    let pageCompanies = ref(1)
    let pageCountCompanies = ref(null)
    let errors = ref([])

    return {
      date: new Date().getFullYear(),
      useradministrador: userStore.administrador,
      userid_company: userStore.id_empresa,
      id_responsavel: userStore.id,
      name_responsavel: userStore.full_name,
      useTrainingStore: useTrainingStore,
      route: route,
      router: router,
      url_api: url,
      hover: hover,
      showModal: showModal,
      showModalVoluntary: showModalVoluntary,
      showModalCourse: ref(false),
      searchCompany: searchCompany,
      listCompanies: listCompanies,
      pageCompanies: pageCompanies,
      pageCountCompanies: pageCountCompanies,
      searchVoluntary: ref(null),
      listVoluntarys: ref(null),
      pageVoluntarys: ref(1),
      pageCountVoluntarys: ref(null),
      searchCourse: ref(null),
      listCourses: ref(null),
      pageCourses: ref(1),
      pageCountCourses: ref(null),
      errors: errors,
      error: errors,
      id_empresa: ref(null),
      isLoading: ref(false),
      id: ref(null),
      id_cliente: ref(null),
      razaoSocial: ref(null),
      id_usuario: ref(null),
      nome_usuario: ref(null),
      id_course: ref(null),
      nome_course: ref(null),
      nr: ref(null),
      it: ref(null),
      grupo: ref(null),
      aprovado: ref(null),
      online: ref(null),
      dt_curso: ref(null),
      dt_vencimento: ref(null),

    }
  },
  methods: {
    getCompanyList() {
      // Make a GET request to the API
      axios.get(this.url_api + '/api/companysearch/' + this.searchCompany + '?page=' + this.pageCompanies)
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountCompanies = response.data.page_count1
          this.listCompanies = response.data.paginate1.data
        })
        .catch((error) => {
          // Handle any errors
          console.error('Error fetching data:', error);
          this.$snotify.error('Verifique novamente !');
        });
    },
    getVoluntaryList() {

      axios.get(this.url_api + '/api/userssearch/' + this.searchVoluntary + '?page=' + this.pageVoluntarys)
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountVoluntarys = response.data.page_count
          this.listVoluntarys = response.data.paginate.data

        })
        .catch((error) => {
          // Handle any errors
          console.error('Error fetching data:', error);
          this.$snotify.error('Verifique novamente !');
        });

    },
    getCourseList() {

      axios.get(this.url_api + '/api/coursesearch/' + this.searchCourse + '?page=' + this.pageCourses)
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountCourses = response.data.page_count
          this.listCourses = response.data.paginate.data
        })
        .catch((error) => {
          // Handle any errors
          console.error('Error fetching data:', error);
          this.$snotify.error('Verifique novamente !');
        });

    },

    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleModalVoluntary() {
      this.showModalVoluntary = !this.showModalVoluntary;
    },
    toggleModalCourse() {
      this.showModalCourse = !this.showModalCourse;
    },
    selectCompany(event) {
      this.id_cliente = event.id
      this.razaoSocial = event.razaoSocial

      if (this.userid_company !== this.id_cliente) {
        this.$snotify.error('Igreja não pertence a ' + this.razaoSocial);
        this.id_cliente = null
        this.razaoSocial = null
      } else {

        this.$snotify.info('Escolheu a : ' + this.razaoSocial);

      }
    },
    selectVoluntary(event) {
      this.id_usuario = event.id
      this.nome_usuario = event.full_name
      this.id_cliente = event.id_empresa

      if (this.userid_company !== this.id_cliente) {
        this.id_usuario = null
        this.nome_usuario = null
        this.$snotify.error('Voluntário não pertence a essa igreja ');
      } else {

        this.$snotify.info('Escolheu a : ' + this.nome_usuario);

      }
    },
    selectCourse(event) {
      this.id_course = event.id
      this.nr = event.nr
      this.nome_course = event.nome_course
      this.it = event.it
      this.grupo = event.grupo
      this.online = event.online
      this.$snotify.info('Escolheu a : ' + this.nome_course);
    },
    onSubmit() {
      this.isLoading = true;
      setTimeout(() => this.isLoading = false, 1000)
    },
    register() {
      // console.log('Dentro register')

      this.error = []

      let data = new FormData();

      data.append('id_responsavel', this.id_responsavel || '')
      data.append('id_cliente', this.id_cliente || '')
      data.append('razaoSocial', this.razaoSocial || '')
      data.append('nome_usuario', this.nome_usuario || '')
      data.append('nome_course', this.nome_course || '')
      data.append('nr', this.nr || '')
      data.append('it', this.it || '')
      data.append('grupo', this.grupo || '')
      data.append('aprovado', this.aprovado || '')
      data.append('online', this.online || '')
      data.append('dt_vencimento', this.dt_vencimento || '')
      data.append('dt_curso', this.dt_curso || '')

      axios.post(this.url_api + '/api/training', {
        id_empresa: this.id_empresa,
        id_responsavel: this.id_responsavel,
        id_cliente: this.id_cliente,
        nome_voluntario: this.nome_usuario,
        nome_treinamento: this.nome_course,
        nr: this.nr,
        it: this.it,
        grupo: this.grupo,
        aprovado: this.aprovado,
        online: this.online,
        dt_vencimento: this.dt_vencimento,
        dt_curso: this.dt_curso

      })
        .then(() => {
          this.$snotify.success('Salvo com sucesso !');
        })
        .catch((error) => {
          // Handle any errors
          this.errors = error.response.data.errors;
          // console.error('Error /api/users:', error);
          this.$snotify.error('Verificar !');
          return error;
        });
    },
    onPageChange() {
      this.getCompanyList();
      this.getVoluntaryList();
      this.register();
    }

  },
  mounted() {

  }
};
</script>

<!-- <template>
  <div>
    <AdmTopNavigation />
  </div>
  <div class="
        container 
        mx-auto 
        my-12
        h-auto
        flex 
        flex-wrap 
        justify-between 
        items-start 
        text-sm 
        p-8 
        pt-8 
        pb-4 
        box-border 
        border-4 
        border-gray-400 
        bg-gray-200">
    <p class="text-2xl text-center text-black font-bold">Admin: Cadastro de Treinamento</p>
    <RouterLinkButton btnText="Voltar" color="sky" tipo="fas" icone="arrow-left" url="/admlistatreinamento" />
    <div class="mx-auto py-4">
      <div class="flex flex-wrap font-bold text-gray-100">
        <div class="w-full mt-4">
          <div class="text-gray-900 text-xl">
            {{ id_cliente }} - {{ razaoSocial }}
          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex mt-4 mb-8">
          <div class="w-full md:w-1/2 px-3">
            <TextInput label="Pesquisar empresa" placeholder="Pesquisar..." v-model:input="searchCompany"
              inputType="text" />
          </div>
          <div class="w-full md:w-1/2 px-3">
            <form @submit.prevent="onSubmit" @click="getCompanyList">
              <LoadingButton :isLoading="isLoading" class="
              block w-full
              bg-green-500
              text-white
              rounded-sm
              py-3 text-sm
              tracking-wide
              transform 
              motion-safe:hover:-translate-y-1 
              text-blue-700 
              motion-safe:hover:scale-110 
              transition 
              ease-in-out">
                Pesquisar empresa
              </LoadingButton>
            </form>
          </div>
          <div class="mx-auto py-4">
            <table class="table-auto text-xs">
              <thead>
                <tr class="text-gray-600 bg-gray-300">
                  <th class="border px-4 py-2">Id</th>
                  <th class="border px-4 py-2">Empresa</th>
                  <th class="border px-4 py-2"></th>
                  <th class="border px-4 py-2">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-gray-500 bg-gray-100" v-for="listCompany in listCompanies" :key="listCompany">
                  <td>{{ listCompany.id }}</td>
                  <td>{{ listCompany.razaoSocial }}</td>
                  <td>{{ listCompany.nomeFantasia }}</td>
                  <td>
                    <div class="text-lg">
                      <SubmitFormButton btnText="Escolha" @click="selectCompany(listCompany)" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="flex items-center justify-center p-2 ">
              <v-pagination v-model="pageCompanies" :pages="pageCompanies" :range-size="1" active-color="#337aff"
                @update:modelValue="listCompanies" />
            </div>
          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex mt-4 mb-8">
          <div class="w-full md:w-1/2 px-3">
            <TextInput label="Pesquisar voluntário" placeholder="Pesquisar..." v-model:input="searchVoluntary"
              inputType="text" />
          </div>
          <div class="w-full md:w-1/2 px-3">
            <form @submit.prevent="onSubmit" @click="getVoluntaryList">
              <LoadingButton :isLoading="isLoading" class="
              block w-full
              bg-green-500
              text-white
              rounded-sm
              py-3 text-sm
              tracking-wide
              transform 
              motion-safe:hover:-translate-y-1 
              text-blue-700 
              motion-safe:hover:scale-110 
              transition 
              ease-in-out">
                Pesquisar voluntário
              </LoadingButton>
            </form>
          </div>
          <div class="mx-auto py-4">
            <table class="table-auto text-xs">
              <thead>
                <tr class="text-gray-600 bg-gray-300">
                  <th class="border px-4 py-2">Id</th>
                  <th class="border px-4 py-2">Voluntário</th>
                  <th class="border px-4 py-2">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-gray-500 bg-gray-100" v-for="listVoluntary in listVoluntarys" :key="listVoluntary">
                  <td>{{ listVoluntary.id }}</td>
                  <td>{{ listVoluntary.full_name }}</td>
                  <td>
                    <div class="text-lg">
                      <SubmitFormButton btnText="Escolha" @click="selectVoluntary(listVoluntary)" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="flex items-center justify-center p-2 ">
              <v-pagination v-model="pageVoluntarys" :pages="pageCountVoluntarys" :range-size="1" active-color="#337aff"
                @update:modelValue="listVoluntarys" />
            </div>
          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex mt-4 mb-8">
          <div class="w-full md:w-1/2 px-3">
            <TextInput label="Pesquisar curso" placeholder="Pesquisar..." v-model:input="searchCourse" inputType="text" />
          </div>
          <div class="w-full md:w-1/2 px-3">
            <form @submit.prevent="onSubmit" @click="getCourseList">
              <LoadingButton :isLoading="isLoading" class="
              block w-full
              bg-green-500
              text-white
              rounded-sm
              py-3 text-sm
              tracking-wide
              transform 
              motion-safe:hover:-translate-y-1 
              text-blue-700 
              motion-safe:hover:scale-110 
              transition 
              ease-in-out">
                Pesquisar curso
              </LoadingButton>
            </form>
          </div>
          <div class="mx-auto py-4">
            <table class="table-auto text-xs">
              <thead>
                <tr class="text-gray-600 bg-gray-300">
                  <th class="border px-4 py-2">Id</th>
                  <th class="border px-4 py-2">NR</th>
                  <th class="border px-4 py-2">Curso</th>
                  <th class="border px-4 py-2">It</th>
                  <th class="border px-4 py-2">Grupo</th>
                  <th class="border px-4 py-2">Online</th>
                  <th class="border px-4 py-2">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-gray-500 bg-gray-100" v-for="listCourse in listCourses" :key="listCourse">
                  <td>{{ listCourse.id }}</td>
                  <td>{{ listCourse.nr }}</td>
                  <td>{{ listCourse.nome_course }}</td>
                  <td>{{ listCourse.it }}</td>
                  <td>{{ listCourse.grupo }}</td>
                  <td>{{ listCourse.online }}</td>
                  <td>
                    <div class="text-lg">
                      <SubmitFormButton btnText="Escolha" @click="selectCourse(listCourse)" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="flex items-center justify-center p-2 ">
              <v-pagination v-model="pageCourses" :pages="pageCountCourses" :range-size="1" active-color="#337aff"
                @update:modelValue="listCourses" />
            </div>
          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex mt-4 mb-6">
          <div class="w-full text-gray-900 md:w-full px-6">
            <TextInput label="Nome do voluntário" placeholder="Digitar o nome do voluntário !"
              v-model:input="nome_usuario" inputType="hidden"
              :error="errors.nome_usuario ? errors.nome_usuario[0] : ''" />
            {{ nome_usuario }}
          </div>
          <div class="w-full text-gray-900 md:w-full px-6">
            <TextInput label="Nome treinamento" placeholder="Digitar o nome do nome treinamento !"
              v-model:input="nome_course" inputType="hidden" :error="errors.nome_course ? errors.nome_course[0] : ''" />
            {{ nome_course }}
          </div>
          <div class=" w-full text-gray-900 md:w-full px-6">
            <TextInput label="NR" placeholder="Digitar o NR !" v-model:input="nr" inputType="hidden" />
            {{ nr }}
          </div>
          <div class=" w-full text-gray-900 md:w-full px-6">
            <TextInput label="IT" placeholder="Digitar o IT !" v-model:input="it" inputType="hidden" />
            {{ it }}
          </div>
          <div class=" w-full text-gray-900 md:w-full px-6">
            <TextInput label="GRUPO" placeholder="Digitar o grupo !" v-model:input="grupo" inputType="hidden" />
            {{ grupo }}
          </div>
          <div class=" w-full text-gray-900 md:w-full px-6">
            <TextInput label="APROVADO" placeholder="Digitar aprovado !" v-model:input="aprovado" inputType="text" />
            {{ aprovado }}
          </div>
          <div class=" w-full text-gray-900 md:w-full px-6">
            <TextInput label="ONLINE" placeholder="Digitar online !" v-model:input="online" inputType="hidden" />
            {{ online }}
          </div>
          <div class="w-full text-gray-900 md:w-full px-6">
            <TextInputDate label="DATA CURSO " placeholder="Digitar data curso !" v-model:input="dt_curso"
              inputType="text" :error="errors.dt_curso ? errors.dt_curso[0] : ''" />
            <div class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{{ dt_curso }}</div>
          </div>
          <div class="w-full text-gray-900 md:w-full px-6">
            <TextInputDate label="Vencimento" placeholder="Digitar data de vencimento !" v-model:input="dt_vencimento"
              inputType="text" :error="errors.dt_vencimento ? errors.dt_vencimento[0] : ''" />
            <div class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">{{ dt_vencimento }}</div>
          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex flex-wrap mt-8 mb-6">
          <form @submit.prevent="onSubmit" @click="register">
            <LoadingButton :isLoading="isLoading" class="
              block w-full
              bg-green-500
              text-white
              rounded-sm
              py-3 text-sm
              tracking-wide
              transform 
              motion-safe:hover:-translate-y-1 
              text-blue-700 
              motion-safe:hover:scale-110 
              transition 
              ease-in-out">
              Salvar
            </LoadingButton>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="mx-auto py-4">
    <div class="flex flex-wrap font-bold text-gray-100">
      <div class="bg-green-500 w-full h-1"></div>
      <div class="flex flex-wrap md:w-1/2 mt-4 mb-8">
        <div class="w-full md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            NR
          </label>
          <p class="block tracking-wide text-gray-700 text-xs font-bold mb-6">
            NRs foram criadas nos anos 70
            Com o andar da carruagem, a preocupação com a saúde dos operários cresceu. Em 1977, no Brasil, foi instituído
            o conceito de medicina e segurança do trabalho para garantir os direitos dos trabalhadores.
            Somente um ano depois, o Ministério do Trabalho aprovou a portaria 3.214. Ela regulamentou as normas
            regulamentadoras ou NRs. Hoje são, ao todo, 37 NRs que abordam as mais diferentes áreas, desde o trabalho em
            altura até o emprego em espaços confinados.
            Basicamente, as normas regulamentadoras visam preservar a segurança e a saúde de todos os trabalhadores, seja
            em relação a acidentes ou doenças laborais. </p>
        </div>
        <div class="w-full md:mb-0">
          <p class="block tracking-wide text-gray-700 text-xs font-bold mb-6">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Certificados devem ser atualizados
            </label>
            Outra questão que envolve a certificação em NRs é a atualização dos certificados. Entre as 37 NRs existentes,
            a maioria necessita de atualização. Ela é traduzida em forma da reciclagem dos certificados.
          </p>
        </div>
        <div class="w-full md:mb-0">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            FONTE :
          </label>
          <p class="block tracking-wide text-gray-700 text-xs font-bold mb-6">
            https://ibqp.com.br
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { useRouter } from 'vue-router'
import { ref } from "vue";
import AdmTopNavigation from "@/components/parcial/adm/AdmTopNavigation.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import LoadingButton from '@/components/global/LoadingButton.vue';
import SubmitFormButton from '@/components/global/SubmitFormButton.vue';

const router = useRouter()
const isLoading = ref(false);

function onSubmit() {
  isLoading.value = true;
  setTimeout(() => isLoading.value = false, 1000)
}

let searchCompany = ref(null)
let listCompanies = ref(null)
let pageCompanies = ref(1)
let pageCountCompanies = ref(null)

let searchVoluntary = ref(null)
let listVoluntarys = ref(null)
let pageVoluntarys = ref(1)
let pageCountVoluntarys = ref(null)

let searchCourse = ref(null)
let listCourses = ref(null)
let pageCourses = ref(1)
let pageCountCourses = ref(null)

let errors = ref([]);
let id_cliente = ref(null);
let razaoSocial = ref(null);
let id_usuario = ref(null);
let nome_usuario = ref(null);
let id_course = ref(null);
let nome_course = ref(null);
let nr = ref(null);
let it = ref(null);
let grupo = ref(null);
let aprovado = ref(null);
let online = ref(null);
let dt_curso = ref(null);
let dt_vencimento = ref(null);

const url = process.env.VUE_APP_API_URL;
let url_api = url

const register = async () => {

  errors.value = []

  try {

    let res = await axios.post(url_api + '/api/training', {
      id_cliente: id_cliente.value,
      nome_voluntario: nome_usuario.value,
      nome_treinamento: nome_course.value,
      nr: nr.value,
      it: it.value,
      grupo: grupo.value,
      aprovado: aprovado.value,
      online: online.value,
      dt_curso: dt_curso.value,
      dt_vencimento: dt_vencimento.value
    })

    // console.log(res)

    router.push("admlistatreinamento");

  } catch (err) {

    console.log(err)
    errors.value = err.response.data.errors

  }

}

const getCompanyList = async () => {
  try {

    let res2 = await axios.get(url_api + '/api/companysearch/' + searchCompany.value + '?page=' + pageCompanies.value)

    // console.log(res2)
    pageCountCompanies.value = res2.data.page_count1
    listCompanies.value = res2.data.paginate1.data


  } catch (err) {
    console.log(err)
  }

}

function selectCompany(event) {

  id_cliente.value = event.id
  razaoSocial.value = event.razaoSocial

}

const getVoluntaryList = async () => {
  try {

    let resVoluntary = await axios.get(url_api + '/api/userssearch/' + searchVoluntary.value + '?page=' + pageVoluntarys.value)

    // console.log(resVoluntary)
    pageCountVoluntarys.value = resVoluntary.data.page_count
    listVoluntarys.value = resVoluntary.data.paginate.data


  } catch (err) {
    console.log(err)
  }

}

function selectVoluntary(event) {

  id_usuario.value = event.id
  nome_usuario.value = event.full_name

}

const getCourseList = async () => {
  try {

    let resCourse = await axios.get(url_api + '/api/coursesearch/' + searchCourse.value + '?page=' + pageCourses.value)

    // console.log(resCourse)
    pageCountCourses.value = resCourse.data.page_count
    listCourses.value = resCourse.data.paginate.data


  } catch (err) {
    console.log(err)
  }

}

function selectCourse(event) {

  id_course.value = event.id
  nr.value = event.nr
  nome_course.value = event.nome_course
  it.value = event.it
  grupo.value = event.grupo
  online.value = event.online

}

</script> -->
