<template>
  <div class="w-full bg-gray-800 relative z-20 text-gray-100">
    <div class="container mx-auto max-w-4xl h-full flex flex-wrap justify-between items-start text-sm p-6 pt-8 pb-4">
      <div class="mb-6">
        <FullScreenModal />
      </div>
      <div class="flex mt-4 mb-6">
        <div class="w-full md:w-5/6 px-3">
          <div class="text-white text-2xl">LCAM</div>
        </div>
        <div class="w-full">
          {{ userStore.full_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import FullScreenModal from "@/components/estrutura/FullScreenModal.vue";
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/user-store'

const router = useRouter()
const userStore = useUserStore()

if (userStore.full_name == null) {
  router.push("/login");
}

</script>
