<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div
            class="container mx-auto my-12 h-auto flex flex-wrap justify-between items-start text-sm p-8 pt-8 pb-4 box-border border-4 border-gray-400 bg-gray-100"
          >
            <a class="text-2xl text-center text-black font-bold"
              >Admin: Cadastro de Produto</a
            >
            <RouterLinkButton
              btnText=""
              color="sky"
              tipo="fas"
              icone="arrow-left"
              url="/admlistaProduto"
            />
            <div class="bg-green-500 w-full h-1"></div>
            <a class="text-black font-bold"
              >Empresa : {{ id_empresa }}-{{ razaoSocial }}</a
            >
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModal()"
              >
                Escolha a Empresa que pertence o Produto !
              </button>
              <div
                v-if="showModal"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Empresa</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModal()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o nome da Empresa"
                            placeholder="Pesquisar..."
                            v-model:input="searchCompany"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form @submit.prevent="onSubmit" @click="getCompanyList">
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-sky-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-gray-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Empresa</th>
                                <th class="border px-4 py-2"></th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listCompany in listCompanies"
                                :key="listCompany"
                              >
                                <td>{{ listCompany.id }}</td>
                                <td>{{ listCompany.razaoSocial }}</td>
                                <td>{{ listCompany.nomeFantasia }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectCompany(listCompany)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageCompanies"
                              :pages="pageCountCompanies"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listCompanies"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-green-500 bg-transparent border border-solid border-green-500 hover:bg-green-500 hover:text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModal()"
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="mx-auto py-4">
              <div class="flex flex-wrap font-bold text-gray-100">
                <div class="w-full mt-4">
                  <div class="text-gray-900 text-xl">Código Produto : {{ id }}</div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-full px-3">
              <div class="bg-green-500 w-full h-1"></div>
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div
                  class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                >
                  <div class="w-full md:w-full px-6">
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput
                        label="Código de barra"
                        placeholder="Digitar o Código de barra !"
                        v-model:input="codigo_barra"
                        inputType="text"
                        :error="errors.codigo_barra ? errors.codigo_barra[0] : ''"
                      />
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput
                        label="Nome do produto"
                        placeholder="Digitar o nome do produto !"
                        v-model:input="nome_produto"
                        inputType="text"
                        :error="errors.nome_produto ? errors.nome_produto[0] : ''"
                      />
                      {{ nome_produto }}
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput
                        label="CA"
                        placeholder="Digitar a CA !"
                        v-model:input="ca"
                        inputType="text"
                      />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInputDate
                        label="Vencimento"
                        placeholder="Digitar data de vencimento !"
                        v-model:input="dt_vencimento"
                        inputType="text"
                        :error="errors.dt_vencimento ? errors.dt_vencimento[0] : ''"
                      />
                      <div
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        {{ dt_vencimento }}
                      </div>
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInputDate
                        label="Vencimento Hidrostático"
                        placeholder="Digitar data de Hidrostático !"
                        v-model:input="dt_hidrostatico"
                        inputType="text"
                        :error="errors.dt_hidrostatico ? errors.dt_hidrostatico[0] : ''"
                      />
                      <div
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        {{ dt_hidrostatico }}
                      </div>
                    </div>
                    <div class="w-full md:w-full px-6">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-state"
                      >
                        Categoria
                      </label>
                      <div class="relative">
                        <select
                          v-model="categoria"
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-state"
                        >
                          <option>EXTINTOR</option>
                          <option>ESCRITÓRIO</option>
                          <option>VENDA</option>
                        </select>
                      </div>
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInput
                        label="Local"
                        placeholder="Digitar o local !"
                        v-model:input="local"
                        inputType="text"
                      />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInput
                        label="Suporte"
                        placeholder="Digitar o suporte !"
                        v-model:input="suporte"
                        inputType="text"
                      />
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput
                        label="Imposto %"
                        placeholder="Digitar o imposto !"
                        v-model:input="imposto"
                        inputType="text"
                      />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInput
                        label="Marca"
                        placeholder="Digitar a marca !"
                        v-model:input="marca"
                        inputType="text"
                      />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="grid-state"
                      >
                        Classe / Unidade
                      </label>
                      <div class="relative">
                        <select
                          v-model="unidade"
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-state"
                        >
                          <option>UN</option>
                          <option>A</option>
                          <option>B</option>
                          <option>C</option>
                          <option>D</option>
                        </select>
                      </div>
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInput
                        label="Estoque"
                        placeholder="Digitar a qtd de estoque !"
                        v-model:input="estoque"
                        inputType="text"
                      />
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput
                        label="Estoque mínimo"
                        placeholder="Digitar a qtd de estoque mínimo !"
                        v-model:input="estoque_minimo"
                        inputType="text"
                      />
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput
                        label="Preço Custo"
                        placeholder="Digitar o preço do produto !"
                        v-model:input="preco_custo"
                        inputType="text"
                      />
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput
                        label="Preço Venda"
                        placeholder="Digitar o preço do produto !"
                        v-model:input="preco_venda"
                        inputType="text"
                      />
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput
                        label="Lucro"
                        placeholder="Esse é lucro do produto !"
                        v-model:input="preco_lucro"
                        inputType="text"
                      />
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput
                        label="Numéro contábil"
                        placeholder="Digitar o Numéro contábil !"
                        v-model:input="cod_conta"
                        inputType="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="flex flex-wrap mt-8 mb-6">
              <form @submit.prevent="onSubmit" @click="register">
                <LoadingButton
                  :isLoading="isLoading"
                  class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                >
                  Salvar
                </LoadingButton>
              </form>
              <vue-snotify />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputCep from "@/components/global/TextInputCep.vue";
import TextInputCelular from "@/components/global/TextInputCelular.vue";
import TextInputTelefone from "@/components/global/TextInputTelefone.vue";
import TextInputRg from "@/components/global/TextInputRg.vue";
import TextInputCpf from "@/components/global/TextInputCpf.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import LoadingButton from "@/components/global/LoadingButton.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";

import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/store/user-store";

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    LoadingButton,
    TextInput,
    SubmitFormButton,
    TextInputTelefone,
    TextInputCelular,
    TextInputRg,
    TextInputCpf,
    TextInputDate,
    TextInputCep,
  },
  name: "regular-modal",
  data() {
    const userStore = useUserStore();
    const route = useRoute();
    const router = useRouter();

    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);
    let showModal = ref(false);

    let searchCompany = ref(null);
    let listCompanies = ref(null);
    let pageCompanies = ref(1);
    let pageCountCompanies = ref(null);
    let errors = ref([]);

    return {
      date: new Date().getFullYear(),
      useradministrador: userStore.administrador,
      userid_company: userStore.id_empresa,
      route: route,
      router: router,
      url_api: url,
      hover: hover,
      showModal: showModal,
      searchCompany: searchCompany,
      listCompanies: listCompanies,
      pageCompanies: pageCompanies,
      pageCountCompanies: pageCountCompanies,
      errors: errors,
      error: errors,
      isLoading: ref(false),
      id: ref(null),
      id_razaoSocial: ref(null),
      razaoSocial: ref(null),
      id_fornecedor: ref(null),
      codigo_barra: ref(null),
      nome_produto: ref(null),
      categoria: ref(null),
      marca: ref(null),
      unidade: ref(null),
      local: ref(null),
      suporte: ref(null),
      estoque: ref(null),
      estoque_minimo: ref(null),
      imposto: ref(null),
      preco_custo: ref(null),
      preco_venda: ref(null),
      preco_lucro: ref(null),
      ca: ref(null),
      dt_vencimento: ref(null),
      dt_hidrostatico: ref(null),
      cod_conta: ref(null),
    };
  },
  methods: {
    getCompanyList() {
      // Make a GET request to the API
      axios
        .get(
          this.url_api +
            "/api/companysearch/" +
            this.searchCompany +
            "?page=" +
            this.pageCompanies
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountCompanies = response.data.page_count1;
          this.listCompanies = response.data.paginate1.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    selectCompany(event) {
      if (this.useradministrador == 1) {
        this.id_empresa = event.id;
        this.razaoSocial = event.razaoSocial;
        this.$snotify.info("Escolheu a : " + this.razaoSocial);
      } else {
        this.id_empresa = null;
        this.razaoSocial = null;
        this.$snotify.error("Colaborador não é administrador !");
      }
    },
    onSubmit() {
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 1000);
    },
    register() {
      this.error = [];

      let data = new FormData();

      data.append("id_empresa", this.id_empresa || "");
      data.append("id_fornecedor", this.id_fornecedor || "");
      data.append("codigo_barra", this.codigo_barra || "");
      data.append("nome_produto", this.nome_produto || "");
      data.append("categoria", this.categoria || "");
      data.append("marca", this.marca || "");
      data.append("unidade", this.unidade || "");
      data.append("local", this.local || "");
      data.append("suporte", this.suporte || "");
      data.append("estoque", this.estoque || "");
      data.append("estoque_minimo", this.estoque_minimo || "");
      data.append("imposto", this.imposto || "");
      data.append("preco_custo", this.preco_custo || "");
      data.append("preco_venda", this.preco_venda || "");
      data.append("preco_lucro", this.preco_lucro || "");
      data.append("ca", this.ca || "");
      data.append("dt_vencimento", this.dt_vencimento || "");
      data.append("dt_hidrostatico", this.dt_hidrostatico || "");
      data.append("cod_conta", this.cod_conta || "");
      
      // console.log(data);

      axios
        .post(this.url_api + "/api/stock", {
          id_cliente: this.id_empresa,
          codigo_barra: this.codigo_barra,
          nome_produto: this.nome_produto,
          categoria: this.categoria,
          marca: this.marca,
          unidade: this.unidade,
          local: this.local,
          suporte: this.suporte,
          estoque: this.estoque,
          estoque_minimo: this.estoque_minimo,
          imposto: this.imposto,
          preco_custo: this.preco_custo,
          preco_venda: this.preco_venda,
          preco_lucro: this.preco_lucro,
          ca: this.ca,
          dt_vencimento: this.dt_vencimento,
          dt_hidrostatico: this.dt_hidrostatico,
          cod_conta: this.cod_conta
        })
        .then(() => {
          this.$snotify.success("Salvo com sucesso !");
        })
        .catch((error) => {
          // Handle any errors
          // console.error('Error /api/company:', error);
          this.errors = error.response.data.errors;
          this.$snotify.error("Verificar digitação !");
          return error;
        });
    },
    onPageChange() {
      this.register();
      this.getCompanyList();
    },
  },
  mounted() {},
};
</script>
