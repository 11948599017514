<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
      </div>
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <div class="flex flex-wrap mt-4">
          <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                  <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-base text-blueGray-700">
                      EMPRESAS
                      <RouterLinkButton btnText="" color="sky" tipo="fas" icone="arrow-left" url="dashboard" />
                      <RouterLinkButton v-if="administrador == 1" btnText="" color="sky" tipo="fas" icone="plus"
                        url="/admcadempresa" />
                    </h3>
                  </div>
                </div>
              </div>
              <div class="block w-full overflow-x-auto">
                <table class="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr class="bg-gray-300">
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Ação
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Empresa
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="listCompany in listCompanies" :key="listCompany.id">
                      <td
                        class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <router-link :to="'admeditempresa/' + listCompany.id" class="
                                underline
                                text-blue-500
                                hover:text-blue-600
                            ">
                          <fas :icon="['fas', 'pen']" />
                        </router-link>
                      </td>
                      <th
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {{ listCompany.nomeFantasia }}
                      </th>
                      <hr>
                    </tr>
                  </tbody>
                </table>
                <div class="flex items-center justify-center p-2 ">
                  <v-pagination v-model="page" :pages="pageCount" :range-size="1" active-color="#337aff"
                    @update:modelValue="getCompanyList" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import VPagination from "@hennge/vue3-pagination";
import V3Pagination from "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useUserStore } from '@/store/user-store';

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    VPagination,
    V3Pagination
  },

  data() {

    const userStore = useUserStore();
    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);

    let listCompanies = ref([]);
    let page = ref(1);
    let pageCount = ref(null);

    return {
      date: new Date().getFullYear(),
      administrador: userStore.administrador,
      company: userStore.id_empresa,
      url_api: url,
      hover: hover,

      listCompanies: listCompanies,
      page: page,
      pageCount: pageCount

    }
  },
  methods: {
    getCompanyList() {
      // Make a GET request to the API
      if (this.administrador == 1) {
        axios.get(this.url_api + '/api/company?page=' + this.page)
          .then((response) => {
            this.pageCount = response.data.page_count
            this.listCompanies = response.data.paginate.data
          })
          .catch((error) => {
            // Handle any errors
            console.error('Error getCompanyList:', error);
          });
      } else {
        // axios.get(this.url_api + '/api/company?page=' + this.page)
        axios.get(this.url_api + '/api/listcompany/' + this.company + '?page=' + this.page)
          .then((response) => {

            this.pageCount = response.data.page_count
            this.listCompanies = response.data.paginate.data
          })
          .catch((error) => {
            // Handle any errors
            console.error('Error getCompanyList:', error);
          });
      }
    },
    onPageChange() {
      this.getCompanyList();
    }
  },
  mounted() {

    this.getCompanyList();

  },

};
</script>

