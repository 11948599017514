import axios from "axios";
import { defineStore } from "pinia";

const url = process.env.VUE_APP_API_URL;

let url_api = url;

export const useSupplierStore = defineStore("solicitationsupplier", {
  state: () => ({
    id: null,
    id_solicitacao: null,
    codigo: null,
    id_fornecedor: null,
    nome_fornecedor: null,
    id_cliente: null,
    razaoSocial: null,
    descricao: null,
    unidade: null,
    quantidade: null,
    vlrunitario: null,
    vlrtotal: null,
    dt_emissao: null,
    dt_retirada: null,
    dt_entrega: null,
    situacao: null,

    id_item: null,
    id_prod_item: null,
    descricao_item: null,
    unidade_item: null,
    quantidade_item: null,
    valor_totalP_item: null,
    deleted_at: null,
  }),
  actions: {
    setSolicitationsupplierIdDetails(res) {
      //Busca do banco de dados
      // console.log("setSolicitationIdDetails", res.data);
      this.$state.id = res.data.id;
    },

    async fetchByIdSolicitationsupplier(id) {
      let res = await axios.get(url_api + "/api/salesorderentry/" + id);
      // console.log(res.data);
      this.$state.id = res.data.salesorderentry.id;
      this.$state.id_cliente = res.data.salesorderentry.id_cliente;
      this.$state.razaoSocial = res.data.salesorderentry.company.razaoSocial;
      this.$state.id_fornecedor = res.data.salesorderentry.id_fornecedor;
      this.$state.nome_fornecedor = res.data.salesorderentry.supplier.razaoSocial;
      this.$state.dt_emissao = res.data.dt_emissao;
      this.$state.dt_retirada = res.data.dt_retirada;
      this.$state.dt_entrega = res.data.dt_entrega;
      this.$state.vlrtotal = res.data.salesorderentry.valorTotalPedido;
    },

    async fetchListItemSolicitationsupplier(id) {
      let resItem = await axios.get(url_api + "/api/salesoitementrylist/" + id);
      // console.log(res.data);
      this.$state.id_item = resItem.data.id;
      this.$state.id_pedido_item = resItem.data.id_pedido_item;
      this.$state.id_prod_item = resItem.data.id_prod_item;
      this.$state.nome_produto_item = resItem.data.nome_produto;
      this.$state.codigo_barra_item = resItem.data.codigo_barra;
      this.$state.unidade_item = resItem.data.unidade;
      this.$state.quantidade_item = resItem.data.quantidade;
      this.$state.valor_unitario_item = resItem.data.valor_unitario;
      this.$state.valor_totalP_item = resItem.data.valor_totalProduto;
    },

    clearIdSolicitationsupplier() {
      this.$state.id_solicitacao = null;
      this.$state.codigo = null;
      this.$state.id_razaoSocial = null;
      this.$state.unidade = null;
      this.$state.quantidade = null;
      this.$state.unidade = null;
      this.$state.vlrunitario = null;
      this.$state.vlrtotal = null;
      this.$state.dt_emissao = null;
      this.$state.dt_retirada = null;
      this.$state.dt_entrega = null;
      this.$state.id_item = null;
      this.$state.id_prod_item = null;
      this.$state.descricao_item = null;
      this.$state.unidade_item = null;
      this.$state.quantidade_item = null;
      this.$state.situacao = null;
      this.$state.deleted_at = null;
    },
  },
  persist: true,
});
