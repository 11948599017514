<template>
  <div>
    <AdmTopNavigation />
  </div>
  <div class="container mx-auto h-full flex flex-wrap justify-between items-start text-sm p-6 pt-8 pb-4">
    <div class="mx-auto py-4">
      <div class="flex flex-wrap font-bold text-gray-100">
        <div class="w-full mt-4">
          <div class="text-gray-900 text-xl">Admin</div>
          <RouterLinkButton btnText="Voltar" color="sky" icone="arrow-left" url="dashboard" />
        </div>
        <div class="w-full bg-green-500 h-1"></div>
        <div class="box-border m-2 h-18 w-34 p-4border-4 border-gray-400 bg-gray-200">
          <RouterLinkButton class="
                transform 
                motion-safe:hover:-translate-y-1 
                text-blue-700 
                motion-safe:hover:scale-110 
                transition 
                ease-in-out 
                duration-300" btnText="Usuários" color="green" icone="gears" url="admlistauser" />
        </div>
        <div class="box-border m-2 h-18 w-34 p-4border-4 border-gray-400 bg-gray-200">
          <RouterLinkButton class="
                transform 
                motion-safe:hover:-translate-y-1 
                text-blue-700 
                motion-safe:hover:scale-110 
                transition 
                ease-in-out 
                duration-300" btnText="Empresa" color="green" icone="gears" url="admlistaempresa" />
        </div>
        <div class="box-border m-2 h-18 w-34 p-4border-4 border-gray-400 bg-gray-200">
          <RouterLinkButton class="
                transform 
                motion-safe:hover:-translate-y-1 
                text-blue-700 
                motion-safe:hover:scale-110 
                transition 
                ease-in-out 
                duration-300" btnText="Fornecedor" color="green" icone="gears" url="admlistafornecedor" />
        </div>
        <div class="box-border m-2 h-18 w-34 p-4border-4 border-gray-400 bg-gray-200">
          <RouterLinkButton class="
                transform 
                motion-safe:hover:-translate-y-1 
                text-blue-700 
                motion-safe:hover:scale-110 
                transition 
                ease-in-out 
                duration-300" btnText="EPIS" color="green" icone="gears" url="admlistaproduto" />
        </div>
        <div class="box-border m-2 h-18 w-34 p-4border-4 border-gray-400 bg-gray-200">
          <RouterLinkButton class="
                transform 
                motion-safe:hover:-translate-y-1 
                text-blue-700 
                motion-safe:hover:scale-110 
                transition 
                ease-in-out 
                duration-300" btnText="Cursos" color="green" icone="gears" url="admlistacourse" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import AdmTopNavigation from "@/components/parcial/adm/AdmTopNavigation.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
let open = ref(false);
</script>
