<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
      </div>
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <div class="flex flex-wrap mt-4">
          <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                  <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-base text-blueGray-700">
                      FORNECEDOR
                      <RouterLinkButton btnText="" color="sky" tipo="fas" icone="arrow-left" url="dashboard" />
                      <RouterLinkButton v-if="administrador == 1" btnText="" color="sky" tipo="fas" icone="plus"
                        url="/admcadfornecedor" />
                    </h3>
                  </div>
                </div>
              </div>
              <div class="block w-full overflow-x-auto">
                <table class="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr class="bg-gray-300">
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Ação
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Nome
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Telefone
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="listSupplier in listSuppliers" :key="listSupplier.id">
                      <td
                        class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <router-link :to="'admeditfornecedor/' + listSupplier.id" class="
                                underline
                                text-blue-500
                                hover:text-blue-600
                            " v-if="administrador == 1">
                          <fas :icon="['fas', 'pen']" />
                        </router-link>
                      </td>
                      <th
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {{ listSupplier.nomeFantasia }}
                      </th>
                      <th
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {{ listSupplier.telefone }}
                      </th>
                      <hr>
                    </tr>
                  </tbody>
                </table>
                <div class="flex items-center justify-center p-2 ">
                  <v-pagination v-model="page" :pages="pageCount" :range-size="1" active-color="#337aff"
                    @update:modelValue="getSupplierList" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import VPagination from "@hennge/vue3-pagination";
import V3Pagination from "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useUserStore } from '@/store/user-store';

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    VPagination,
    V3Pagination
  },

  data() {

    const userStore = useUserStore();
    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);

    let listSuppliers = ref([]);
    let page = ref(1);
    let pageCount = ref(null);

    return {
      date: new Date().getFullYear(),
      administrador: userStore.administrador,
      company: userStore.id_empresa,
      url_api: url,
      hover: hover,

      listSuppliers: listSuppliers,
      page: page,
      pageCount: pageCount

    }
  },
  methods: {
    getSupplierList() {
      // Make a GET request to the API
      if (this.administrador == 1) {
        axios.get(this.url_api + '/api/supplier?page=' + this.page)
          .then((response) => {
            this.pageCount = response.data.page_count
            this.listSuppliers = response.data.paginate.data
          })
          .catch((error) => {
            // Handle any errors
            console.error('Error getSupplierList:', error);
          });
      } else {
        axios.get(this.url_api + '/api/supplier?page=' + this.page)
          // axios.get(this.url_api + '/api/listSuppliers/' + this.company + '?page=' + this.page)
          .then((response) => {
            this.pageCount = response.data.page_count
            this.listSuppliers = response.data.paginate.data
          })
          .catch((error) => {
            // Handle any errors
            console.error('Error getSupplierList:', error);
          });
      }
    },
    onPageChange() {
      this.getSupplierList();
    }
  },
  mounted() {

    this.getSupplierList();

  },

};
</script>




<!-- <template>
  <div>
    <AdmTopNavigation />
  </div>
  <div class="container mx-auto h-full flex flex-wrap justify-between items-start text-sm p-6 pt-8 pb-4">
    <div class="mx-auto py-4">
      <div class="flex flex-wrap font-bold text-gray-100">
        <div class="w-full mt-4">
          <div class="text-gray-900 text-xl">
            Adm: Fornecedor
            <RouterLinkButton btnText="Retornar" color="sky" icone="arrow-left" url="administracao" />
            <RouterLinkButton btnText="Incluir" color="sky" icone="plus" url="/admcadfornecedor" />
          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
      </div>
      <table class="table-auto text-xl">
        <thead>
          <tr class="bg-gray-300">
            <th class="border px-4 py-2">Fornecedor</th>
            <th class="border px-4 py-2">Telefone</th>
            <th class="border px-4 py-2">Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-gray-100" v-for="listSupplier in listSuppliers" :key="listSupplier">
            <td>{{ listSupplier.nomeFantasia }} </td>
            <td>{{ listSupplier.telefone }} </td>
            <td>
              <div class="text-lg">
                <router-link :to="'admeditfornecedor/' + listSupplier.id" class="
                                underline
                                text-blue-500
                                hover:text-blue-600
                            ">
                  Alterar
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex items-center justify-center p-2 ">
        <v-pagination v-model="page" :pages="pageCount" :range-size="1" active-color="#337aff"
          @update:modelValue="getSupplierList" />
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import AdmTopNavigation from "@/components/parcial/adm/AdmTopNavigation.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

const route = useRoute()
const url = process.env.VUE_APP_API_URL;

let url_api = url
let listSuppliers = ref(null)
let page = ref(1)
let pageCount = ref(null)

onMounted(async () => {
  await getSupplierList()
})

const getSupplierList = async () => {
  try {

    let res = await axios.get(url_api + '/api/supplier?page=' + page.value)
    // console.log(res)
    pageCount.value = res.data.page_count
    listSuppliers.value = res.data.paginate.data

  } catch (err) {
    console.log(err)
  }
}

</script> -->
