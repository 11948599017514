<template>
  <div class="flex flex-wrap">
    <div class="relative inline-flex align-middle w-full">
      <button class="text-gray font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 bg-gray-200 ease-linear transition-all duration-150
          font-normal px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 capitalize w-full" type="button"
        v-on:click="toggleDropdown()" ref="btnDropdownRef">
        ADMINISTRAÇÃO
      </button>
      <div v-bind:class="{ 'hidden': !dropdownPopoverShow, 'block': dropdownPopoverShow }"
        class="bg-white text-base z-50 float-left py-2 list-none rounded shadow-lg mt-1" style="min-width:12rem"
        ref="popoverDropdownRef">
        <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
          <li class="inline-flex">
            <RouterLinkButton btnText="Colaboradores" color="sky" tipo="fas" icone="users" url="/admlistauser" />
          </li>
          <li class="inline-flex">
            <RouterLinkButton btnText="Empreas" color="sky" tipo="fas" icone="place-of-worship" url="/admlistaempresa" />
          </li>
          <li class="inline-flex">
            <RouterLinkButton btnText="Fornecedores" color="sky" tipo="fas" icone="truck-front"
              url="/admlistafornecedor" />
          </li>
          <li class="inline-flex">
            <RouterLinkButton btnText="Estoque" color="sky" tipo="fas" icone="socks" url="/admlistaproduto" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import { createPopper } from "@popperjs/core";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";

export default {
  name: "dropdown",
  components: {
    RouterLinkButton
  },
  data() {
    return {
      dropdownPopoverShow: false
    }
  },
  methods: {
    toggleDropdown: function () {
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start"
        });
      }
    }
  }
}
</script>