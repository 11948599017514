import axios from "axios";
import { defineStore } from "pinia";

const url = process.env.VUE_APP_API_URL;

let url_api = url;

export const useVolunteersStore = defineStore("volunteers", {
  state: () => ({
    id: null,
    id_empresa: null,
    razaoSocial: null,
    full_name: null,
    login_name: null,
    celular: null,
    cargo: null,
    email: null,
    rg: null,
    cpf: null,
    password: null,
    location: null,
    description: null,
    image: null,
    administrador: null,
    usuario: null,
    cliente: null,
    token: null,
    cod_siga: null,
    dt_nascimento: null,
    deleted_at: null,
  }),
  actions: {
    setvolunteersIdDetails(res) {
      //Busca do banco de dados
      // console.log("setvolunteersIdDetails", res.data);
      this.$state.id = res.data.id;
      this.$state.id_empresa = res.data.id_empresa;
      this.$state.full_name = res.data.full_name;
      this.$state.login_name = res.data.login_name;
      this.$state.celular = res.data.celular;
      this.$state.cargo = res.data.cargo;
      this.$state.email = res.data.email;
      this.$state.rg = res.data.rg;
      this.$state.cpf = res.data.cpf;
      this.$state.password = res.data.password;
      this.$state.location = res.data.location;
      this.$state.description = res.data.description;
      this.$state.administrador = res.data.administrador;
      this.$state.usuario = res.data.usuario;
      this.$state.cliente = res.data.cliente;
      this.$state.cod_siga = res.data.cod_siga;
      this.$state.dt_nascimento = res.data.dt_nascimento;
      this.$state.deleted_at = res.data.deleted_at;
    },

    async fetchByIdvolunteers(id) {
      let res = await axios.get(url_api + "/api/users/" + id);
      // console.log(res.data);
      this.$state.id = res.data.id;
      this.$state.id_empresa = res.data.id_empresa;
      if (res.data.company) {
        this.$state.razaoSocial = res.data.company.razaoSocial;
      } else {
        this.$state.razaoSocial = null;
      }
      this.$state.full_name = res.data.full_name;
      this.$state.login_name = res.data.login_name;
      this.$state.celular = res.data.celular;
      this.$state.cargo = res.data.cargo;
      this.$state.email = res.data.email;
      this.$state.rg = res.data.rg;
      this.$state.cpf = res.data.cpf;
      this.$state.password = res.data.password;
      this.$state.location = res.data.location;
      this.$state.description = res.data.description;
      this.$state.administrador = res.data.administrador;
      this.$state.usuario = res.data.usuario;
      this.$state.cliente = res.data.cliente;
      this.$state.cod_siga = res.data.cod_siga;
      this.$state.dt_nascimento = res.data.dt_nascimento;
      this.$state.deleted_at = res.data.deleted_at;
    },

    clearIdvolunteers() {
      this.$state.id = null;
      this.$state.id_empresa = null;
      this.$state.razaoSocial = null;
      this.$state.full_name = null;
      this.$state.login_name = null;
      this.$state.celular = null;
      this.$state.cargo = null;
      this.$state.email = null;
      this.$state.rg = null;
      this.$state.cpf = null;
      this.$state.password = null;
      this.$state.location = null;
      this.$state.description = null;
      this.$state.image = null;
      this.$state.administrador = null;
      this.$state.usuario = null;
      this.$state.cliente = null;
      this.$state.token = null;
      this.$state.cod_siga = null;
      this.$state.dt_nascimento = null;
      this.$state.deleted_at = null;
    },
  },
  persist: true,
});
