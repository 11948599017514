import axios from "axios";
import { defineStore } from "pinia";

const url = process.env.VUE_APP_API_URL;

let url_api = url;

export const useTrainingsStore = defineStore("trainings", {
  state: () => ({
    id: null,
    id_responsavel: null,
    name_responsavel: null,
    id_cliente: null,
    razaoSocial: null,
    nome_voluntario: null,
    nome_treinamento: null,
    nr: null,
    it: null,
    grupo: null,
    aprovado: null,
    online: null,
    dt_curso: null,
    dt_vencimento: null,
  }),
  actions: {
    setTrainingIdDetails(res) {
      //Busca do banco de dados
      console.log("settrainingIdDetails", res.data);
      this.$state.id = res.data.id;
      this.$state.nome_voluntario = res.data.nome_voluntario;
      this.$state.nome_treinamento = res.data.nome_treinamento;
      this.$state.nr = res.data.nr;
      this.$state.it = res.data.it;
      this.$state.grupo = res.data.grupo;
      this.$state.aprovado = res.data.aprovado;
      this.$state.online = res.data.online;
      this.$state.dt_vencimento = res.data.dt_vencimento;
      this.$state.dt_curso = res.data.dt_curso;
      this.$state.id_responsavel = res.data.id_responsavel;
      this.$state.name_responsavel = res.data.user.name;
      this.$state.id_cliente = res.data.id_cliente;
      this.$state.razaoSocial = res.data.razaoSocial;
    },

    async fetchByIdTraining(id) {
      let res = await axios.get(url_api + "/api/training/" + id);
      // console.log(res.data);
      this.$state.id = res.data.training.id;
      this.$state.nome_voluntario = res.data.training.nome_voluntario;
      this.$state.nome_treinamento = res.data.training.nome_treinamento;
      this.$state.nr = res.data.training.nr;
      this.$state.it = res.data.training.it;
      this.$state.grupo = res.data.training.grupo;
      this.$state.aprovado = res.data.training.aprovado;
      this.$state.online = res.data.training.online;
      this.$state.dt_vencimento = res.data.training.dt_vencimento;
      this.$state.dt_curso = res.data.training.dt_curso;
      if (res.data.training.user) {
        this.$state.id_responsavel = res.data.training.id_responsavel;
        this.$state.name_responsavel = res.data.training.user.name;
      } else {
        this.$state.id_responsavel = null;
        this.$state.name_responsavel = null;
      }
      if (res.data.training.company) {
        this.$state.id_cliente = res.data.training.id_cliente;
        this.$state.razaoSocial = res.data.training.company.razaoSocial;
      } else {
        this.$state.id_cliente = null;
        this.$state.razaoSocial = null;
      }
    },

    clearIdTraining() {
      this.$state.id = null;
      this.$state.nome_voluntario = null;
      this.$state.nome_treinamento = null;
      this.$state.nr = null;
      this.$state.it = null;
      this.$state.grupo = null;
      this.$state.aprovado = null;
      this.$state.online = null;
      this.$state.dt_vencimento = null;
      this.$state.dt_curso = null;
      this.$state.id_responsavel = null;
      this.$state.name_responsavel = null;
      this.$state.id_cliente = null;
      this.$state.razaoSocial = null;
    },
  },
  persist: true,
});
