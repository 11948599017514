<template>
  <div class="flex flex-col h-screen p-4">
    <div class="flex-grow overflow-auto border border-gray-300 p-4 rounded-lg">
      <div v-for="message in messages" :key="message.id" class="mb-2">
        <span class="font-semibold">{{ message.user }}:</span>
        <span>{{ message.text }}</span>
      </div>
    </div>
    <input
      v-model="newMessage"
      @keyup.enter="sendMessage"
      type="text"
      class="border rounded-lg p-2 mt-4"
      placeholder="Type your message..."
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      newMessage: "",
      messages: [],
      user: "User", // You can modify this or get it dynamically
    };
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim()) {
        this.messages.push({
          id: Date.now(),
          user: this.user,
          text: this.newMessage,
        });
        this.newMessage = "";
      }
    },
  },
};
</script>
