<template>
  <div>
    <HomeSection />
  </div>
</template>

<script setup>
import HomeSection from '@/components/HomeSection.vue';

function verify(code) {
  var cookiestring = RegExp(code + "=[^;]+").exec(document.cookie);
  return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
}

// console.log(verify('version'));

if (verify('version') !== '0.1.1') {
  caches.open('devsite.pwa_RUNTINE_V3').then(function (cache) {
    cache.delete('/css/*.*');
    cache.delete('/js/*.*');
    cache.delete('/*.*');
  })
  localStorage.clear();
  // console.info('script:', 'confirme a atualização.');
  // alert("Portuguese: Precisamos atualizar a página, esta é uma versão antiga.\n English: We need to update the page, you are on an old version.\n Español: Necesitamos actualizar la página, estás en una versión anterior.");
  // console.info('script:', 'recarregando...');
  // location.reload(true);
}

</script>
