<template>
  <div class="container mx-auto">
    <h1 class="text-2xl font-bold mb-4">Vue Chat App</h1>
    <Chat />
  </div>
</template>

<script>
import NavbarComponent from "@/components/Navbar.vue";
import Chat from "@/components/chat/Chat.vue";

export default {
  components: {
    Chat,
  },
};
</script>
