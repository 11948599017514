<template>
  <div>
    <AdmTopNavigation />
  </div>
  <div class="
        container 
        mx-auto 
        my-12
        h-auto
        flex 
        flex-wrap 
        justify-between 
        items-start 
        text-sm 
        p-8 
        pt-8 
        pb-4 
        box-border 
        border-4 
        border-gray-400 
        bg-gray-200">

    <p class="text-2xl text-center text-black font-bold">Solicitação : Alterar Saída </p>
    <RouterLinkButton btnText="Retornar" color="sky" tipo="fas" icone="arrow-left" url="/solicitacao" />
    <div class="mx-auto py-4">
      <div class="flex flex-wrap font-bold text-gray-100">
        <div class="w-full mt-4">
          <div class="text-gray-900 text-xl">
            Número {{ id_solicitacao }}
          </div>
          <RouterLinkButton btnText="Imprimir" color="sky" tipo="fas" icone="arrow-left" url="" />
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex mt-4 mb-8">
          <div class="w-full md:w-1/2 px-3">
            <TextInput label="Pesquisar empresa" placeholder="Pesquisar..." v-model:input="searchCompany"
              inputType="text" />
          </div>
          <div class="w-full md:w-1/2 px-3">
            <form @submit.prevent="onSubmit" @click="getCompanyList">
              <LoadingButton :isLoading="isLoading" class="
              block w-full
              bg-green-500
              text-white
              rounded-sm
              py-3 text-sm
              tracking-wide
              transform 
              motion-safe:hover:-translate-y-1 
              text-blue-700 
              motion-safe:hover:scale-110 
              transition 
              ease-in-out">
                Pesquisar empresa
              </LoadingButton>
            </form>
          </div>
          <div class="mx-auto py-4">
            <table class="table-auto text-xs">
              <thead>
                <tr class="text-gray-600 bg-gray-300">
                  <th class="border px-4 py-2">Id</th>
                  <th class="border px-4 py-2">Solicitante</th>
                  <th class="border px-4 py-2"></th>
                  <th class="border px-4 py-2">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-gray-500 bg-gray-100" v-for="listCompany in listCompanies" :key="listCompany">
                  <td>{{ listCompany.id }}</td>
                  <td>{{ listCompany.razaoSocial }}</td>
                  <td>{{ listCompany.nomeFantasia }}</td>
                  <td>
                    <div class="text-lg">
                      <SubmitFormButton btnText="Escolha" @click="selectCompany(listCompany)" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="flex items-center justify-center p-2 ">
              <v-pagination v-model="pageCompanies" :pages="pageCountCompanies" :range-size="1" active-color="#337aff"
                @update:modelValue="listCompanies" />
            </div>
          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex mt-4 mb-8">
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInput label="Id" placeholder="" v-model:input="id_razaoSocial" inputType="hidden" readonly />
            {{ id_razaoSocial }}
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInput label="Solicitante" placeholder="Solicitante" v-model:input="razaoSocial" inputType="hidden" />
            {{ razaoSocial }}
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInputDate label="Data emissão" placeholder="Digitar emissão !" v-model:input="dt_emissao"
              inputType="text" />
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInputDate label="Data retirada" placeholder="Digitar retirada !" v-model:input="dt_retirada"
              inputType="text" />
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInputDate label="Data entrega" placeholder="Digitar entrega !" v-model:input="dt_entrega"
              inputType="text" />
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-3">
            <TextInput label="Vlr Solicitação" placeholder="Valor total" v-model:input="vlrtotal" inputType="hidden" />
            {{ vlrtotal }}
          </div>
          <div class="w-full md:w-1/2 px-3">
            <form @submit.prevent="onSubmit" @click="alterSolicitacao">
              <LoadingButton :isLoading="isLoading" class="
              block w-full
              bg-green-500
              text-white
              rounded-sm
              py-3 text-sm
              tracking-wide
              transform 
              motion-safe:hover:-translate-y-1 
              text-blue-700 
              motion-safe:hover:scale-110 
              transition 
              ease-in-out">
                Alterar/Finalizar
              </LoadingButton>
            </form>
          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="mx-auto py-4">
          <div class="w-full ok px-6">
            <TextInput label="Pesquisar Nome Produto" placeholder="Pesquisar..." v-model:input="searchproduct"
              inputType="text" />
          </div>
        </div>
        <div class="flex flex-wrap mt-8 mb-6">
          <form @submit.prevent="onSubmit" @click="getStockListProd">
            <LoadingButton :isLoading="isLoading" class="
              block w-full
              bg-green-500
              text-white
              rounded-sm
              py-3 text-sm
              tracking-wide
              transform 
              motion-safe:hover:-translate-y-1 
              text-blue-700 
              motion-safe:hover:scale-110 
              transition 
              ease-in-out">
              Pesquisar produto
            </LoadingButton>
          </form>
        </div>
        <div class="mx-auto py-4">
          <table class="table-auto text-xs">
            <thead>
              <tr class="text-gray-600 bg-gray-300">
                <th class="border px-4 py-2">Código barra</th>
                <th class="border px-4 py-2">Produto</th>
                <th class="border px-4 py-2">Estoque</th>
                <th class="border px-4 py-2">Preço venda</th>
                <th class="border px-4 py-2">Action(Ação)</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-gray-500 bg-gray-100" v-for="listStock in listStocks" :key="listStock">
                <td>{{ listStock.codigo_barra }}</td>
                <td>{{ listStock.nome_produto }}</td>
                <td>{{ listStock.estoque }}</td>
                <td>{{ listStock.preco_venda }}</td>
                <td>
                  <div class="text-lg">
                    <SubmitFormButton btnText="Escolha" @click="selectprod(listStock)" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex items-center justify-center p-2 ">
            <v-pagination v-model="page" :pages="pageCountProducts" :range-size="1" active-color="#337aff"
              @update:modelValue="listStocks" />
          </div>
        </div>
        <div class="flex mt-2 mb-2">
          <div class="w-full text-gray-700 md:w-1/6 px-3">
            <TextInput label="" placeholder="" v-model:input="codigo" inputType="hidden" />
            <TextInput label="Código barra" placeholder="Código barra" v-model:input="codigo_barra" inputType="hidden" />
            {{ codigo_barra }}
          </div>
          <div class="w-full text-gray-700 md:w-1/2 px-8">
            <TextInput label="Descrição" placeholder="Descricao" v-model:input="descricao" inputType="hidden" />
            {{ descricao }}
          </div>
          <div class="w-full text-gray-700 md:w-1/12 px-3">
            <TextInput label="Unidade" placeholder="Unidade" v-model:input="unidade" inputType="hidden" />
            {{ unidade }}
          </div>
          <div class="w-full md:w-1/12 px-3">
            <TextInput label="Quantidade" placeholder="Quantidade" v-model:input="quantidade" inputType="text" />
            <SubmitFormButton btnText="Qtd + Vlr Unitátio" @click="multiplicarprod()" />
          </div>
          <div class="w-full md:w-auto px-3">
            <TextInput label="Valor unitário" placeholder="Valor unitário" v-model:input="vlrunitario" inputType="text" />
          </div>
          <div class="w-full text-gray-700 md:w-auto px-3">
            <TextInput label="Valor total" placeholder="Valor total" v-model:input="vlrtotal_item" inputType="hidden" />
            {{ vlrtotal_item }}
          </div>
          <div class="flex flex-wrap mt-8 mb-6">
            <div class="w-full md:w-auto px-6">
              <form @submit.prevent="onSubmit" @click="registerItemSolicitacao">
                <LoadingButton :isLoading="isLoading" class="
                  block w-full
                  bg-green-500
                  text-white
                  rounded-sm
                  py-3 text-sm
                  tracking-wide
                  transform 
                  motion-safe:hover:-translate-y-1 
                  text-blue-700 
                  motion-safe:hover:scale-110 
                  transition 
                  ease-in-out">
                  Include(Incluir)
                </LoadingButton>
              </form>
            </div>

          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="text-gray-600 text-xl">
          Itens da solicitação
        </div>
        <div class="bg-green-500 w-full h-1"></div>
        <table class="table-auto text-xl">
          <thead>
            <tr class="text-gray-600 bg-gray-300">
              <th class="border px-4 py-2">Solicitação</th>
              <th class="border px-4 py-2">Codigo barra</th>
              <th class="border px-4 py-2">Descrição</th>
              <th class="border px-4 py-2">Classe(Un)</th>
              <th class="border px-4 py-2">Quantidade</th>
              <th class="border px-4 py-2">Valor unitário</th>
              <th class="border px-4 py-2">Valor total</th>
              <th class="border px-4 py-2">Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-gray-500 bg-gray-100" v-for="listSolicitItem in listSolicitItems" :key="listSolicitItem">
              <td>{{ listSolicitItem.id_pedido_item }}</td>
              <td>{{ listSolicitItem.codigo_barra }}</td>
              <td>{{ listSolicitItem.nome_produto }}</td>
              <td>{{ listSolicitItem.unidade }}</td>
              <td>{{ listSolicitItem.quantidade }}</td>
              <td>{{ listSolicitItem.valor_unitario }}</td>
              <td>{{ listSolicitItem.valor_totalProduto }}</td>
              <td>
                <SubmitFormButton btnText="Deletar" class="underline text-red-500 hover:text-red-600"
                  @click="deleteItemSolitacao(listSolicitItem)" />
              </td>
            </tr>
          </tbody>
        </table>
        <div class="bg-green-500 w-full h-1"></div>
        <div class="flex items-center justify-center p-2 ">
          <v-pagination v-model="pageItems" :pages="pageCountItems" :range-size="1" active-color="#337aff"
            @update:modelValue="getSolicitItemList" />
        </div>

      </div>
    </div>
  </div>
</template>
<script setup>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import AdmTopNavigation from "@/components/parcial/adm/AdmTopNavigation.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import SubmitFormButton from '@/components/global/SubmitFormButton.vue';
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import { useRoute, useRouter } from 'vue-router';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import LoadingButton from '@/components/global/LoadingButton.vue';
import { useSolicitationStore } from '@/store/solicitation-store';

const route = useRoute()
const router = useRouter()
const isLoading = ref(false)

function onSubmit() {
  isLoading.value = true;
  setTimeout(() => isLoading.value = false, 1000)
}

let errors = ref([]);
let open = ref(false)
let listStocks = ref(null)
let pageStocks = ref(1)
let pageCountStocks = ref(null)
let searchproduct = ref(null)
let searchCompany = ref(null)
let listCompanies = ref(null)
let pageCompanies = ref(1)
let pageCountCompanies = ref(null)
let listSolicitItems = ref(null)
let pageItems = ref(1)
let pageCountItems = ref(null)


let id_solicitacao = ref(null);
let codigo = ref(null);
let codigo_barra = ref(null);
let id_razaoSocial = ref(null);
let razaoSocial = ref(null);
let descricao = ref(null);
let unidade = ref(null);
let quantidade = ref(0);
let vlrunitario = ref(null);
let vlrtotal = ref(null);
let dt_emissao = ref(null);
let dt_retirada = ref(null);
let dt_entrega = ref(null);
let situacao = ref(null);

let vlrtotal_item = ref(null);
let id_itemsolicitacao = ref(null)

const url = process.env.VUE_APP_API_URL;
let url_api = url

const solicitationsStore = useSolicitationStore()

onMounted(async () => {

  await solicitationsStore.fetchByIdSolicitation(route.params.id)

  id_solicitacao.value = solicitationsStore.id_solicitacao || null
  id_razaoSocial.value = solicitationsStore.id_razaoSocial || null
  razaoSocial.value = solicitationsStore.razaoSocial || null
  vlrtotal.value = solicitationsStore.vlrtotal || null
  dt_emissao.value = solicitationsStore.dt_emissao || null
  dt_retirada.value = solicitationsStore.dt_retirada || null
  dt_entrega.value = solicitationsStore.dt_entrega || null
  situacao.value = solicitationsStore.situacao || null

  await getSolicitItemList()


})

const getSolicitItemList = async () => {
  try {

    let resItem = await axios.get(url_api + '/api/salesoitemlist/' + route.params.id + '?page=' + pageItems.value)

    // console.log(resItem)

    pageCountItems.value = resItem.data.page_countItem
    listSolicitItems.value = resItem.data.paginateItem.data
    vlrtotal.value = resItem.data.valorTotalPedido

  } catch (err) {
    console.log(err)
  }

}

const alterSolicitacao = async () => {

  if (dt_emissao.value == null) {
    alert('Enter request date(Digitar data de emissão)')
  }

  try {

    let res = await axios.put(url_api + '/api/salesorder/' + route.params.id, {
      id_cliente: id_razaoSocial.value,
      razaoSocial: razaoSocial.value,
      dt_emissao: dt_emissao.value,
      dt_retirada: dt_retirada.value,
      dt_entrega: dt_entrega.value,
      valorTotalPedido: vlrtotal.value

    })

    // console.log(res)

    // id_solicitacao.value = res.data.salesorder.id
    // dt_emissao.value = res.data.salesorder.dt_emissao

  } catch (err) {

    console.log(err)
    errors.value = err.response.errors

  }

}

const registerItemSolicitacao = async () => {

  if (id_solicitacao.value == null) {
    alert('Register solicitation (Registrar solicitação)')
  }

  if (dt_emissao.value == null) {
    alert('Enter request date(Digitar data de emissão)')
  }

  if (codigo_barra.value == null) {
    alert('Enter request product(Incluir produto)')
  }

  errors.value = []

  try {

    let res = await axios.post(url_api + '/api/salesoitem', {

      id_pedido_item: id_solicitacao.value,
      id_prod_item: codigo.value,
      codigo_barra: codigo_barra.value,
      nome_produto: descricao.value,
      dt_emissao: dt_emissao.value,
      unidade: unidade.value,
      valor_unitario: vlrunitario.value,
      quantidade: quantidade.value,
      valor_totalProduto: vlrtotal_item.value

    })

    // console.log(res)

    await getSolicitItemList()
    alert('Product successfully included in the request(Produto incluido com sucesso na solicitação)')

  } catch (err) {

    console.log(err)
    errors.value = err.response.data.errors

  }

}

const getStockListProd = async () => {

  errors.value = []

  try {

    let res = await axios.get(url_api + '/api/salesordersearch/' + searchproduct.value + '?page=' + pageStocks.value)

    pageCountStocks.value = res.data.page_count
    listStocks.value = res.data.paginate.data

    // console.log(res)

  } catch (err) {

    console.log(err)
    errors.value = err.response.data.errors

  }

}

function selectprod(event) {

  //console.log(event)
  // alert(`Selecionou ! : ` + event.id + ' - ' + event.nome_produto)
  codigo.value = event.id
  codigo_barra.value = event.codigo_barra
  descricao.value = event.nome_produto
  unidade.value = event.unidade
  vlrunitario.value = event.preco_venda
}

function multiplicarprod() {

  // alert(`Selecionou !`)
  vlrtotal_item.value = quantidade.value * vlrunitario.value

}

const getCompanyList = async () => {
  try {

    let res2 = await axios.get(url_api + '/api/companysearch/' + searchCompany.value + '?page=' + pageCompanies.value)

    console.log(res2)
    pageCountCompanies.value = res2.data.page_count1
    listCompanies.value = res2.data.paginate1.data


  } catch (err) {
    console.log(err)
  }
}

function selectCompany(event) {

  // console.log(event)
  // alert(`Selecionou ! : ` + event.id + ' - ' + event.razaoSocial)
  id_razaoSocial.value = event.id
  razaoSocial.value = event.razaoSocial

}

const deleteItemSolitacao = async (event) => {

  try {

    id_itemsolicitacao = event.id

    let resDelete = await axios.delete(url_api + '/api/salesoitem/' + id_itemsolicitacao)

    // console.log(resDelete)

    alert(`Selecionou item para delete! : ` + resDelete.data)

    await getSolicitItemList()

  } catch (err) {
    console.log(err)
  }

}

</script>
