<template>
  <div>
    <a class="text-gray-300 block" href="" v-on:click="toggleDropdown($event)" ref="btnDropdownRef">
      <div class="items-center flex">
        Bem vindo {{ full_name }}
        <span class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
          <img alt="..." class="w-full rounded-full align-middle border-none shadow-lg"
            src="@/assets/img/default_avatar.png" />
        </span>
      </div>
    </a>

    <div ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1" v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow
      }" style="min-width: 12rem">
      <router-link :to="'admedituser/' + id" class="
                                font-normal
                                text-blue-300
                                hover:text-blue-600
                            ">
        Alterar
      </router-link>
      <div class="h-0 my-2 border border-solid border-blueGray-100"></div>
      <a class="text-gray-400 block hover:text-gray-700" href="" v-on:click="logout()">Logout</a>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { createPopper } from "@popperjs/core";
import { useUserStore } from '@/store/user-store';

export default {

  mounted() {
    // console.log(this.full_name);
    if (this.full_name == null) {
      this.router.push("/login")
    }
  },
  data() {

    const router = useRouter();
    const userStore = useUserStore();
    const url = process.env.VUE_APP_API_URL;

    return {
      dropdownPopoverShow: false,
      url_api: url,
      full_name: userStore.full_name,
      id: userStore.id,
      router: router,
    };
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-end"
        });
      }
    },
    logout: function () {

      localStorage.clear();
      this.router.push('/')

    },
  }
};

</script>

