import axios from "axios";
import { defineStore } from "pinia";

const url = process.env.VUE_APP_API_URL;

let url_api = url;

export const useSuppliersStore = defineStore("supplier", {
  state: () => ({
    id: null,
    id_responsavel: null,
    razaoSocial: null,
    nomeFantasia: null,
    cpfcnpj: null,
    telefone: null,
    celular: null,
    end_cep: null,
    end_logradouro: null,
    end_complemento: null,
    end_numero: null,
    end_cidade: null,
    end_bairro: null,
    end_uf: null,
    status: null,
    obs: null,
    deleted_at: null,
  }),
  actions: {
    setSupplierIdDetails(res) {
      //Busca do banco de dados
      // console.log("setsupplierIdDetails", res.data);
      this.$state.id = res.data.id;
      this.$state.id_responsavel = res.data.id_responsavel;
      this.$state.razaoSocial = res.data.razaoSocial;
      this.$state.nomeFantasia = res.data.nomeFantasia;
      this.$state.cpfcnpj = res.data.cpfcnpj;
      this.$state.telefone = res.data.telefone;
      this.$state.end_cep = res.data.end_cep;
      this.$state.end_logradouro = res.data.end_logradouro;
      this.$state.end_complemento = res.data.end_complemento;
      this.$state.end_numero = res.data.end_numero;
      this.$state.end_cidade = res.data.end_cidade;
      this.$state.end_bairro = res.data.end_bairro;
      this.$state.end_uf = res.data.end_uf;
      this.$state.status = res.data.status;
      this.$state.obs = res.data.obs;
      this.$state.deleted_at = res.data.deleted_at;
    },

    async fetchByIdsupplier(id) {
      let res = await axios.get(url_api + "/api/supplier/" + id);
      // console.log(res.data);
      this.$state.id = res.data.supplier.id;
      this.$state.id_responsavel = res.data.supplier.id_responsavel;
      this.$state.razaoSocial = res.data.supplier.razaoSocial;
      this.$state.nomeFantasia = res.data.supplier.nomeFantasia;
      this.$state.cpfcnpj = res.data.supplier.cpfcnpj;
      this.$state.telefone = res.data.supplier.telefone;
      this.$state.celular = res.data.supplier.celular;
      this.$state.end_cep = res.data.supplier.end_cep;
      this.$state.end_logradouro = res.data.supplier.end_logradouro;
      this.$state.end_complemento = res.data.supplier.end_complemento;
      this.$state.end_numero = res.data.supplier.end_numero;
      this.$state.end_cidade = res.data.supplier.end_cidade;
      this.$state.end_bairro = res.data.supplier.end_bairro;
      this.$state.end_uf = res.data.supplier.end_uf;
      this.$state.status = res.data.supplier.status;
      this.$state.obs = res.data.supplier.obs;
      this.$state.deleted_at = res.data.supplier.deleted_at;
    },

    clearIdsupplier() {
      this.$state.id = null;
      this.$state.id_responsavel = null;
      this.$state.razaoSocial = null;
      this.$state.nomeFantasia = null;
      this.$state.cpfcnpj = null;
      this.$state.telefone = null;
      this.$state.celular = null;
      this.$state.end_cep = null;
      this.$state.end_logradouro = null;
      this.$state.end_complemento = null;
      this.$state.end_numero = null;
      this.$state.end_cidade = null;
      this.$state.end_bairro = null;
      this.$state.end_uf = null;
      this.$state.status = null;
      this.$state.obs = null;
      this.$state.deleted_at = null;
    },
  },
  persist: true,
});
