<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
      </div>
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <div class="flex flex-wrap mt-4">
          <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                  <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-base text-blueGray-700">
                      TREINAMENTOS
                      <RouterLinkButton btnText="" color="sky" tipo="fas" icone="arrow-left" url="dashboard" />
                      <RouterLinkButton btnText="" color="sky" tipo="fas" icone="plus" url="admcadtreinamento" />
                    </h3>
                  </div>
                </div>
              </div>
              <div class="block w-full overflow-x-auto">
                <table class="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr class="bg-gray-300">
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Ação
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Treinamento
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Voluntário
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Igreja
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Aprovado
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="listTraining in listTrainings" :key="listTraining.id">
                      <td
                        class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <router-link :to="'admedittreinamento/' + listTraining.id" class="
                                underline
                                text-blue-500
                                hover:text-blue-600
                            ">
                          <fas :icon="['fas', 'pen']" />
                        </router-link>
                      </td>
                      <td
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        {{ listTraining.nome_treinamento }}
                      </td>
                      <th
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {{ listTraining.nome_voluntario }}
                      </th>
                      <th v-if="listTraining.company !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {{ listTraining.company.nomeFantasia }}
                      </th>
                      <th v-if="listTraining.company == null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      </th>
                      <th
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {{ listTraining.aprovado }}
                      </th>
                    </tr>
                  </tbody>
                </table>
                <div class="flex items-center justify-center p-2 ">
                  <v-pagination v-model="page" :pages="pageCount" :range-size="1" active-color="#337aff"
                    @update:modelValue="getTrainingList" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import VPagination from "@hennge/vue3-pagination";
import V3Pagination from "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useUserStore } from '@/store/user-store';

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    VPagination,
    V3Pagination
  },

  data() {

    const userStore = useUserStore();
    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);

    let listTrainings = ref([]);
    let page = ref(1);
    let pageCount = ref(null);

    return {
      date: new Date().getFullYear(),
      administrador: userStore.administrador,
      company: userStore.id_empresa,
      url_api: url,
      hover: hover,

      listTrainings: listTrainings,
      page: page,
      pageCount: pageCount

    }
  },
  methods: {
    getTrainingList() {
      // Make a GET request to the API
      if (this.administrador == 1) {
        axios.get(this.url_api + '/api/training?page=' + this.page)
          .then((response) => {
            this.pageCount = response.data.page_count
            this.listTrainings = response.data.paginate.data
          })
          .catch((error) => {
            // Handle any errors
            console.error('Error getTrainingList:', error);
          });
      } else {
        // axios.get(this.url_api + '/api/users?page=' + this.page)
        axios.get(this.url_api + '/api/listtraining/' + this.company + '?page=' + this.page)
          .then((response) => {
            // console.log(response.data)
            this.pageCount = response.data.page_count
            this.listTrainings = response.data.paginate.data
          })
          .catch((error) => {
            // Handle any errors
            console.error('Error getTrainingList:', error);
          });
      }
    },
    onPageChange() {
      this.getTrainingList();
    }
  },
  mounted() {

    this.getTrainingList();

  },

};
</script>

<!-- <template>
  <div>
    <AdmTopNavigation />
  </div>
  <div class="container mx-auto w-full flex flex-wrap justify-between items-start text-sm p-6 pt-8 pb-4">
    <div class="mx-auto py-4">
      <div class="flex flex-wrap font-bold text-gray-100">
        <div class="w-full mt-4">
          <div class="text-gray-900 text-xl">
            TREINAMENTOS
          </div>
        </div>
        <div class="
        container 
        mx-auto 
        my-8
        h-auto
        flex 
        flex-wrap 
        justify-between 
        items-start 
        text-sm 
        p-8 
        pt-8 
        pb-6 
        box-border 
        border-4 
        border-gray-400 
        bg-gray-200
        ">
          <div class="container mx-auto max-w-4xl h-16 flex justify-center text-xs md:text-sm">
            <div class="h-full flex items-center px-3">
              <RouterLinkButton btnText="Incluir" color="sky" tipo="fas" icone="plus" url="/admcadtreinamento" />
            </div>
            <div class="h-full flex items-center px-3">
              <RouterLinkButton btnText="PDF" color="sky" tipo="fas" icone="print" url="" @click="gerarPDF" />
            </div>
            <div class="h-full flex items-center px-3">
              <RouterLinkButton btnText="Retornar" color="sky" tipo="fas" icone="arrow-left" url="dashboard" />
            </div>
            <div class="w-full md:w-11/12 px-4">
              <TextInput label="Filtrar por igreja" placeholder="Pesquisar..." v-model:input="filterTraining"
                inputType="text" />
            </div>
            <div class="w-full md:w-1/2 px-4">
              <form @submit.prevent="onSubmit" @click="getfilterTraining">
                <LoadingButton :isLoading="isLoading" class="
              block w-full
              bg-green-500
              text-white
              rounded-sm
              py-4
              text-sm
              tracking-wide
              transform 
              motion-safe:hover:-translate-y-1 
              text-blue-700 
              motion-safe:hover:scale-110 
              transition 
              ease-in-out">
                  Filtrar
                </LoadingButton>
              </form>
              <div class="h-full flex items-center px-3"> -->

              <!-- </div> -->
            <!-- </div>
          </div>
        </div>
      </div>
      <div class="bg-green-500 w-full h-1"></div>
      <table id="my-table" class="table-auto text-xl">
        <thead>
          <tr class="bg-gray-300">
            <th class="border px-4 py-2">ID</th>
            <th class="border px-4 py-2">Igreja</th>
            <th class="border px-4 py-2">Voluntário</th>
            <th class="border px-4 py-2">Treinamento</th>
            <th class="border px-4 py-2">NR</th>
            <th class="border px-4 py-2">IT</th>
            <th class="border px-4 py-2">Aprovado</th>
            <th class="border px-4 py-2">Emissão</th>
            <th class="border px-4 py-2">Vencimento</th>
            <th class="border px-4 py-2">Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-gray-100" v-for="listTraining in  listTrainings " :key="listTraining">
            <td>{{ listTraining.id }}</td>
            <td v-if="open === false">{{ listTraining.nomeFantasia }}</td>
            <td v-else-if="open === true">{{ listTraining.company.nomeFantasia }}</td>
            <td>{{ listTraining.nome_voluntario }}</td>
            <td>{{ listTraining.nome_treinamento }}</td>
            <td>{{ listTraining.nr }}</td>
            <td>{{ listTraining.it }}</td>
            <td>{{ listTraining.aprovado }}</td>
            <td>{{ formatDate(listTraining.dt_curso) }}</td>
            <td>{{ formatDate(listTraining.dt_vencimento) }}</td>
            <td>
              <div class="text-lg">
                <router-link :to="'admedittreinamento/' + listTraining.id" class="
                                underline
                                text-blue-500
                                hover:text-blue-600
                            ">
                  Alterar
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="bg-green-500 w-full h-1"></div>
      <div class="flex items-center justify-center p-2 ">
        <v-pagination v-model="page" :pages="pageCount" :range-size="1" active-color="#337aff"
          @update:modelValue="getTrainingList" />
      </div>
    </div>
  </div>
</template>
  
<script setup>

import axios from 'axios';
import { onMounted, ref } from 'vue';
import AdmTopNavigation from "@/components/parcial/adm/AdmTopNavigation.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import { useRoute } from 'vue-router';
import TextInput from "@/components/global/TextInput.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

const route = useRoute()
const url = process.env.VUE_APP_API_URL;
const doc = new jsPDF();

let open = ref(true)
let url_api = url
let filterTraining = ref(null)
let listTrainings = ref(null)
let page = ref(1)
let pageCount = ref(null)

onMounted(async () => {
  await getTrainingList()
})

const getTrainingList = async () => {
  try {

    let res = await axios.get(url_api + '/api/training?page=' + page.value)
    // console.log(res)
    pageCount.value = res.data.page_count
    listTrainings.value = res.data.paginate.data
    open = ref(true)

  } catch (err) {
    console.log(err)
  }
}

function formatDate(dateString) {
  // console.log(dateString)
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(date);
}

const getfilterTraining = async () => {
  try {

    let res2 = await axios.get(url_api + '/api/trainingsearchCompany/' + filterTraining.value + '?page=' + page.value)

    console.log(res2)
    pageCount.value = res2.data.page_count
    listTrainings.value = res2.data.paginate.data
    open = ref(false)

  } catch (err) {
    console.log(err)
  }
}

const gerarPDF = async () => {
  try {

    doc.text("Treinamentos", 105, 10, null, null, "center");

    autoTable(doc, { html: '#my-table' })

    doc.save('treinamento.pdf')

  } catch (err) {
    console.log(err)
  }

}

</script>
   -->