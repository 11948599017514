<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
      </div>
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <div class="flex flex-wrap mt-4">
          <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
            <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
              <div class="rounded-t mb-0 px-4 py-3 border-0">
                <div class="flex flex-wrap items-center">
                  <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-base text-blueGray-700">
                      PRODUTOS
                      <RouterLinkButton btnText="" color="sky" tipo="fas" icone="arrow-left" url="/dashboard" />
                      <RouterLinkButton btnText="" color="sky" tipo="fas" icone="plus" url="/admcadproduto" />
                    </h3>
                  </div>
                </div>
              </div>
              <div class="block w-full overflow-x-auto">
                <table class="items-center w-full bg-transparent border-collapse">
                  <thead>
                    <tr class="bg-gray-300">
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Ação
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Empresa
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Codigo barra
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Descrição
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Estoque
                      </th>
                      <th
                        class="border border-solid border-green-700 px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                        Vencimento
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="listStock in listStocks" :key="listStock.id">
                      <td
                        class="border border-solid border-indigo-500/100 border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                        <router-link :to="'admeditproduto/' + listStock.id" class="
                                underline
                                text-blue-500
                                hover:text-blue-600
                            ">
                          <fas :icon="['fas', 'pen']" />
                        </router-link>
                      </td>
                      <th v-if="listStock.company !== null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {{ listStock.company.nomeFantasia }}
                      </th>
                      <th v-if="listStock.company == null"
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      </th>
                      <th
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {{ listStock.codigo_barra }}
                      </th>
                      <th
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {{ listStock.nome_produto }}
                      </th>
                      <th
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {{ listStock.estoque }}
                      </th>
                      <th
                        class="border border-solid border-indigo-500/100 border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                        {{ formatDate(listStock.dt_vencimento) }}
                      </th>
                      <hr>
                    </tr>
                  </tbody>
                </table>
                <div class="flex items-center justify-center p-2 ">
                  <v-pagination v-model="page" :pages="pageCount" :range-size="1" active-color="#337aff"
                    @update:modelValue="getStockList" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import VPagination from "@hennge/vue3-pagination";
import V3Pagination from "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useUserStore } from '@/store/user-store';

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    VPagination,
    V3Pagination
  },

  data() {

    const userStore = useUserStore();
    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);

    let listStocks = ref([]);
    let page = ref(1);
    let pageCount = ref(null);

    return {
      date: new Date().getFullYear(),
      administrador: userStore.administrador,
      company: userStore.id_empresa,
      url_api: url,
      hover: hover,

      listStocks: listStocks,
      page: page,
      pageCount: pageCount

    }
  },
  methods: {
    getStockList() {
      // Make a GET request to the API
      if (this.administrador == 1) {
        axios.get(this.url_api + '/api/stock?page=' + this.page)
          .then((response) => {
            console.log(response.data)
            this.pageCount = response.data.page_count
            this.listStocks = response.data.paginate.data
          })
          .catch((error) => {
            // Handle any errors
            console.error('Error getStockList:', error);
          });
      } else {
        // axios.get(this.url_api + '/api/stock?page=' + this.page)
        axios.get(this.url_api + '/api/liststock/' + this.company + '?page=' + this.page)
          .then((response) => {
            this.pageCount = response.data.page_count
            this.listStocks = response.data.paginate.data
          })
          .catch((error) => {
            // Handle any errors
            console.error('Error getStockList:', error);
          });
      }
    },
    onPageChange() {
      this.getStockList();
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(date);
    }

  },
  mounted() {

    this.getStockList();

  },

};
</script>




<!-- <template>
  <div>
    <AdmTopNavigation />
  </div>
  <div class="container mx-auto h-full flex flex-wrap justify-between items-start text-sm p-6 pt-8 pb-4">
    <div class="mx-auto py-4">
      <div class="flex flex-wrap font-bold text-gray-100">
        <div class="w-full mt-4">
          <div class="text-gray-900 text-xl">
            Admin: Produto
            <RouterLinkButton btnText="Retornar" color="sky" tipo="fas" icone="arrow-left" url="administracao" />
            <RouterLinkButton btnText="Incluir" color="green" tipo="fas" icone="plus" url="admcadproduto" />
          </div>
        </div>
        <div class="bg-green-500 w-full h-1"></div>
      </div>
      <table class="table-auto text-xl">
        <thead>
          <tr class="bg-gray-300">
            <th class="border px-4 py-2">Igreja</th>
            <th class="border px-4 py-2">Codigo barra</th>
            <th class="border px-4 py-2">EPI</th>
            <th class="border px-4 py-2">Estoque</th>
            <th class="border px-4 py-2">CA</th>
            <th class="border px-4 py-2">Vencimento</th>
            <th class="border px-4 py-2">Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-gray-100" v-for="listStock in listStocks" :key="listStock">
            <td>{{ listStock.id_cliente }}</td>
            <td>{{ listStock.codigo_barra }}</td>
            <td>{{ listStock.nome_produto }}</td>
            <td>{{ listStock.estoque }}</td>
            <td>{{ listStock.ca }}</td>
            <td>{{ formatDate(listStock.dt_vencimento) }}</td>

            <td>
              <div class="text-lg">
                <router-link :to="'admeditproduto/' + listStock.id" class="
                                underline
                                text-blue-500
                                hover:text-blue-600
                            ">
                  Alterar
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex items-center justify-center p-2 ">
        <v-pagination v-model="page" :pages="pageCount" :range-size="1" active-color="#337aff"
          @update:modelValue="getStockList" />
      </div>

    </div>
  </div>
</template>

<script setup>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import AdmTopNavigation from "@/components/parcial/adm/AdmTopNavigation.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import { useRoute } from 'vue-router';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

const route = useRoute()
const url = process.env.VUE_APP_API_URL;

let open = ref(false)
let url_api = url
let listStocks = ref(null)
let page = ref(1)
let pageCount = ref(null)

onMounted(async () => {
  await getStockList()
})

const getStockList = async () => {
  try {

    let res = await axios.get(url_api + '/api/stock?page=' + page.value)

    // listUsers.value = res.data
    pageCount.value = res.data.page_count
    listStocks.value = res.data.paginate.data


  } catch (err) {
    console.log(err)
  }
}

function formatDate(dateString) {
  // console.log(dateString)
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(date);
}

</script> -->
