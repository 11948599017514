<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div class="
              container 
              mx-auto 
              my-12
              h-auto
              flex 
              flex-wrap 
              justify-between 
              items-start 
              text-sm 
              p-8 
              pt-8 
              pb-4 
              box-border 
              border-4 
              border-gray-400 
              bg-gray-100">
            <a class="text-2xl text-center text-black font-bold">Admin: Alterar colaborador</a>
            <RouterLinkButton btnText="" color="sky" tipo="fas" icone="arrow-left" url='/admlistauser' />
            <div class="bg-green-500 w-full h-1"></div>
            <div class="mx-auto py-4">
              <div class="flex flex-wrap font-bold text-gray-100">
                <div class="w-full mt-4">
                  <div class="text-gray-900 text-xl">
                    Código colaborador: {{ id }}
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <a class="text-black font-bold">Empresa : {{ id_empresa }}-{{ razaoSocial }}</a>
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" v-on:click="toggleModal()">
                Escolha a empresa que pertence o colaborador !
              </button>
              <div v-if="showModal"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 class="text-3xl font-semibold">
                        Empresa
                      </h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModal()">
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput label="Digitar o nome da empresa" placeholder="Pesquisar..."
                            v-model:input="searchCompany" inputType="text" />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form @submit.prevent="onSubmit" @click="getCompanyList">
                            <LoadingButton :isLoading="isLoading" class="
                              block w-full
                              bg-green-500
                              text-white
                              rounded-sm
                              py-3 text-sm
                              tracking-wide
                              transform 
                              motion-safe:hover:-translate-y-1 
                              text-blue-700 
                              motion-safe:hover:scale-110 
                              transition 
                              ease-in-out">
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Empresa</th>
                                <th class="border px-4 py-2">Fantasia</th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="text-gray-500 bg-gray-100" v-for="listCompany in listCompanies"
                                :key="listCompany">
                                <td>{{ listCompany.id }}</td>
                                <td>{{ listCompany.razaoSocial }}</td>
                                <td>{{ listCompany.nomeFantasia }}</td>
                                <td>
                                  <div class="
                                    block w-full
                                    bg-green-500
                                    text-white
                                    rounded-sm
                                    py-3 text-sm
                                    tracking-wide
                                    transform 
                                    motion-safe:hover:-translate-y-1 
                                    text-blue-700 
                                    motion-safe:hover:scale-110 
                                    transition 
                                    ease-in-out">
                                    <SubmitFormButton btnText="Alterar" @click="selectCompany(listCompany)" />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2 ">
                            <v-pagination v-model="pageCompanies" :pages="pageCountCompanies" :range-size="1"
                              active-color="#337aff" @update:modelValue="listCompanies" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button" v-on:click="toggleModal()">
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </div>
            <div class="w-full md:w-full px-3">
              <div class="bg-green-500 w-full h-1"></div>
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none">
                  <div class="w-full md:w-full px-6">
                    <TextInput label="Nome completo *" :labelColor="true" placeholder="Digitar o seu nome completo !"
                      v-model:input="full_name" inputType="text" :error="errors.full_name ? errors.full_name[0] : ''" />
                  </div>
                  <div class="w-full md:w-full px-6">
                    <TextInputDate label="Data nascimento" placeholder="Digitar nascimento !"
                      v-model:input="dt_nascimento" inputType="text" />
                  </div>
                  <div class="w-full md:w-full px-6">
                    <TextInput label="Email" :labelColor="true" placeholder="Digitar o seu E-mail !" v-model:input="email"
                      inputType="text" :error="errors.email ? errors.email[0] : ''" />
                  </div>
                  <div class="w-full md:w-full px-6">
                    <TextInputCelular label="Celular" :labelColor="true" placeholder="Digitar o seu celular !"
                      v-model:input="celular" inputType="text" />
                  </div>
                  <div class="w-full md:w-full px-6">
                    <TextInput label="Cargo" :labelColor="true" placeholder="Digitar o cargo !" v-model:input="cargo"
                      inputType="text" />
                  </div>
                  <div class="w-full md:w-full px-6">
                    <TextInputRg label="RG" :labelColor="true" placeholder="Digitar o seu RG !" v-model:input="rg"
                      inputType="text" />
                  </div>
                  <div class="w-full md:w-full px-6">
                    <TextInputCpf label="CPF" :labelColor="true" placeholder="Digitar o seu CPF !" v-model:input="cpf"
                      inputType="text" />
                  </div>
                  <div class="w-full md:w-full px-6">
                    <TextInput label="Código identificação" :labelColor="true"
                      placeholder="Digitar o código identificação !" v-model:input="cod_siga" inputType="text" />
                  </div>
                </div>
                <div class="bg-green-500 w-full h-1"></div>
                <div v-if="useradministrador == 1" class="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <div class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none">

                    <div class="w-full mt-4">
                      <div class="text-gray-900 text-xl">
                        Perfil de acesso
                      </div>
                    </div>

                    <TextInput label="Nome para login*" :labelColor="true" placeholder="Digitar o seu nome para login"
                      v-model:input="login_name" inputType="text"
                      :error="errors.login_name ? errors.login_name[0] : ''" />

                    <TextInput label="Password *" :labelColor="true" placeholder="Digitar a sua senha !"
                      v-model:input="password" inputType="password" :error="errors.password ? errors.password[0] : ''" />

                    <TextInput label="Confirmar a sua senha*" :labelColor="true" placeholder="Confirmar a sua senha !"
                      v-model:input="confirPassword" inputType="password"
                      :error="errors.password ? errors.password[0] : ''" />

                    <div class="w-full mt-4">

                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-8" for="grid-state">
                        Administrador
                        <input type="checkbox" v-model="administrador" true-value="1" false-value="0">
                      </label>

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="flex flex-wrap mt-8 mb-6">
              <form @submit.prevent="onSubmit" @click="update">
                <LoadingButton :isLoading="isLoading" class="
                  block w-full
                  bg-green-500
                  text-white
                  rounded-sm
                  py-3 text-sm
                  tracking-wide
                  transform 
                  motion-safe:hover:-translate-y-1 
                  text-blue-700 
                  motion-safe:hover:scale-110 
                  transition 
                  ease-in-out">
                  Alterar
                </LoadingButton>
              </form>
              <vue-snotify />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { ref } from 'vue';
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputCelular from "@/components/global/TextInputCelular.vue";
import TextInputRg from "@/components/global/TextInputRg.vue";
import TextInputCpf from "@/components/global/TextInputCpf.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import LoadingButton from '@/components/global/LoadingButton.vue';
import SubmitFormButton from '@/components/global/SubmitFormButton.vue';

import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '@/store/user-store';
import { useVolunteersStore } from '@/store/volunteers-store';

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    LoadingButton,
    TextInput,
    SubmitFormButton,
    TextInputCelular,
    TextInputRg,
    TextInputCpf,
    TextInputDate

  },
  name: "regular-modal",
  data() {

    const userStore = useUserStore();
    const volunteersStore = useVolunteersStore();
    const route = useRoute()
    const router = useRouter()

    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);
    let showModal = ref(false);

    let searchCompany = ref(null)
    let listCompanies = ref(null)
    let pageCompanies = ref(1)
    let pageCountCompanies = ref(null)
    let errors = ref([])

    return {
      date: new Date().getFullYear(),
      useradministrador: userStore.administrador,
      userid_company: userStore.id_empresa,
      volunteersStore: volunteersStore,
      route: route,
      router: router,
      url_api: url,
      hover: hover,
      showModal: showModal,
      searchCompany: searchCompany,
      listCompanies: listCompanies,
      pageCompanies: pageCompanies,
      pageCountCompanies: pageCountCompanies,
      errors: errors,
      error: errors,
      id_empresa: ref(null),
      razaoSocial: ref(null),
      id: ref(null),
      full_name: ref(null),
      login_name: ref(null),
      celular: ref(null),
      cargo: ref(null),
      rg: ref(null),
      cpf: ref(null),
      email: ref(null),
      password: ref(null),
      confirPassword: ref(null),
      administrador: ref(null),
      dt_nascimento: ref(null),
      cod_siga: ref(null),
      isLoading: ref(false),

    }
  },
  methods: {
    getCompanyList() {
      // Make a GET request to the API
      axios.get(this.url_api + '/api/companysearch/' + this.searchCompany + '?page=' + this.pageCompanies)
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountCompanies = response.data.page_count1
          this.listCompanies = response.data.paginate1.data
        })
        .catch((error) => {
          // Handle any errors
          console.error('Error fetching data:', error);
          this.$snotify.error('Verifique novamente !');
        });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    selectCompany(event) {
      
      if (this.useradministrador == 1) {

      this.id_empresa = event.id
      this.razaoSocial = event.razaoSocial
      this.$snotify.info('Escolheu a : ' + this.razaoSocial);

      } else {


        this.id_empresa = null
        this.razaoSocial = null
        this.$snotify.error('Voluntário não é administrador !' );

      }

      
    },
    onSubmit() {
      this.isLoading = true;
      setTimeout(() => this.isLoading = false, 1000)
    },
    async getVolunteers() {
      // Make a GET request to the API
      const response = await this.volunteersStore.fetchByIdvolunteers(this.route.params.id)
      this.id = this.volunteersStore.id || null
      this.id_empresa = this.volunteersStore.id_empresa || null
      this.razaoSocial = this.volunteersStore.razaoSocial || null
      this.full_name = this.volunteersStore.full_name || null
      this.login_name = this.volunteersStore.login_name || null
      this.celular = this.volunteersStore.celular || null
      this.cargo = this.volunteersStore.cargo || null
      this.rg = this.volunteersStore.rg || null
      this.cpf = this.volunteersStore.cpf || null
      this.email = this.volunteersStore.email || null
      this.password = this.volunteersStore.password || null
      this.confirPassword = this.volunteersStore.confirPassword || null
      this.administrador = this.volunteersStore.administrador || null
      this.dt_nascimento = this.volunteersStore.dt_nascimento || null
      this.cod_siga = this.volunteersStore.cod_siga || null
      this.$snotify.info('Aguarde atualizando ... !');
    },
    update() {
      // console.log('Dentro update')

      this.error = []

      let data = new FormData();

      data.append('id_empresa', this.id_empresa || '')
      data.append('full_name', this.full_name || '')
      data.append('celular', this.celular || '')
      data.append('cargo', this.cargo || '')
      data.append('rg', this.rg || '')
      data.append('cpf', this.cpf || '')
      data.append('email', this.email || '')
      data.append('dt_nascimento', this.dt_nascimento || '')
      data.append('cod_siga', this.cod_siga || '')
      data.append('login_name', this.login_name || '')

      if (this.useradministrador == 1) {

        data.append('password', this.password || '')
        data.append('confirPassword', this.confirPassword || '')
        data.append('administrador', this.administrador || '')

      } else {

        this.password = 'asdfghjnull'
        this.confirPassword = 'asdfghjnull'
        this.administrador = 0

        data.append('password', this.password || '')
        data.append('confirPassword', this.confirPassword || '')
        data.append('administrador', this.administrador || '')

      }

      axios.put(this.url_api + '/api/users/' + this.route.params.id, {
        id_empresa: this.id_empresa,
        full_name: this.full_name,
        login_name: this.login_name,
        celular: this.celular,
        cargo: this.cargo,
        rg: this.rg,
        cpf: this.cpf,
        email: this.email,
        password: this.password,
        password_confirmation: this.confirPassword,
        administrador: this.administrador,
        dt_nascimento: this.dt_nascimento,
        cod_siga: this.cod_siga
      })
        .then(() => {
          this.$snotify.success('Salvo com sucesso !');
        })
        .catch((error) => {
          // Handle any errors
          this.errors = error.response.data.errors;
          // console.error('Error /api/users:', error);
          this.$snotify.error('Verificar !');
          return error;
        });
    },
    onPageChange() {
      this.getCompanyList();
      this.getVolunteers();
      this.update();
    }

  },
  mounted() {

    this.getVolunteers();


  }
};
</script>
