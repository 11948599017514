<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div class="
              container 
              mx-auto 
              my-12
              h-auto
              flex 
              flex-wrap 
              justify-between 
              items-start 
              text-sm 
              p-8 
              pt-8 
              pb-4 
              box-border 
              border-4 
              border-gray-400 
              bg-gray-100">
            <a class="text-2xl text-center text-black font-bold">Admin: Cadastro de empresa</a>
            <RouterLinkButton btnText="" color="sky" tipo="fas" icone="arrow-left" url='/admlistaempresa' />
            <div class="bg-green-500 w-full h-1"></div>
            <div class="mx-auto py-4">
              <div class="flex flex-wrap font-bold text-gray-100">
                <div class="w-full mt-4">
                  <div class="text-gray-900 text-xl">
                    Código Empresa : {{ id }}
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-full px-3">
              <div class="bg-green-500 w-full h-1"></div>
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none">
                  <div class="w-full md:w-full px-6">
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput label="Empresa" placeholder="Digitar empresa !" v-model:input="razaoSocial"
                        inputType="text" :error="errors.razaoSocial ? errors.razaoSocial[0] : ''" />
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput label="Nome fantasia" placeholder="Digitar o nome fantasia !"
                        v-model:input="nomeFantasia" inputType="text" />
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInputCnpj label="CNPJ" placeholder="Digitar o CNPJ !" v-model:input="cpfcnpj"
                        inputType="text" />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInput label="Inscrição Estadual" placeholder="Digitar o IE !" v-model:input="insestadual"
                        inputType="text" />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInput label="Incrição Municipal" placeholder="Digitar o IM !" v-model:input="insmunicipal"
                        inputType="text" />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInputTelefone label="Telefone" placeholder="Digitar o telefone !" v-model:input="telefone"
                        inputType="text" />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInputCelular label="Celular" placeholder="Digitar o celular !" v-model:input="celular"
                        inputType="text" />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInputCep label="CEP" placeholder="Digitar o cep !" v-model:input="end_cep" inputType="text" />
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput label="Logradouro" placeholder="Digitar o logradouro !" v-model:input="end_logradouro"
                        inputType="text" />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInput label="Número" placeholder="Digitar o número !" v-model:input="end_numero"
                        inputType="text" />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInput label="Complemento" placeholder="Digitar o complemento !" v-model:input="end_complemento"
                        inputType="text" />
                    </div>
                    <div class="w-full md:w-full px-6">
                      <TextInput label="Município" placeholder="Digitar o município !" v-model:input="end_cidade"
                        inputType="text" />
                    </div>
                    <div class="w-full text-gray-900 md:w-full px-6">
                      <TextInput label="Bairro" placeholder="Digitar o bairro !" v-model:input="end_bairro"
                        inputType="text" />
                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                        ATIVO
                      </label>
                      <div class="relative">
                        <select v-model="status"
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-state">
                          <option>S</option>
                          <option>N</option>
                        </select>
                      </div>
                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                        Matriz
                      </label>
                      <div class="relative">
                        <select v-model="matriz"
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-state">
                          <option>S</option>
                          <option>N</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="flex flex-wrap mt-8 mb-6">
              <form @submit.prevent="onSubmit" @click="register">
                <LoadingButton :isLoading="isLoading" class="
                  block w-full
                  bg-green-500
                  text-white
                  rounded-sm
                  py-3 text-sm
                  tracking-wide
                  transform 
                  motion-safe:hover:-translate-y-1 
                  text-blue-700 
                  motion-safe:hover:scale-110 
                  transition 
                  ease-in-out">
                  Salvar
                </LoadingButton>
              </form>
              <vue-snotify />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputCep from "@/components/global/TextInputCep.vue";
import TextInputCelular from "@/components/global/TextInputCelular.vue";
import TextInputTelefone from "@/components/global/TextInputTelefone.vue";
import TextInputRg from "@/components/global/TextInputRg.vue";
import TextInputCpf from "@/components/global/TextInputCpf.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import LoadingButton from '@/components/global/LoadingButton.vue';
import SubmitFormButton from '@/components/global/SubmitFormButton.vue';

import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '@/store/user-store';
// import { useCompaniesStore } from '@/store/company-store';

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    LoadingButton,
    TextInput,
    SubmitFormButton,
    TextInputTelefone,
    TextInputCelular,
    TextInputRg,
    TextInputCpf,
    TextInputDate,
    TextInputCep

  },
  name: "regular-modal",
  data() {

    const userStore = useUserStore();
    // const companiesStore = useCompaniesStore()
    const route = useRoute()
    const router = useRouter()

    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);
    let errors = ref([])

    return {
      date: new Date().getFullYear(),
      useradministrador: userStore.administrador,
      userid_company: userStore.id_empresa,
      // companiesStore: companiesStore,
      route: route,
      router: router,
      url_api: url,
      hover: hover,
      errors: errors,
      error: errors,
      isLoading: ref(false),
      id: ref(null),
      razaoSocial: ref(null),
      nomeFantasia: ref(null),
      cpfcnpj: ref(null),
      insestadual: ref(null),
      insmunicipal: ref(null),
      telefone: ref(null),
      celular: ref(null),
      end_cep: ref(null),
      end_logradouro: ref(null),
      end_numero: ref(null),
      end_complemento: ref(null),
      end_cidade: ref(null),
      end_bairro: ref(null),
      end_uf: ref(null),
      status: ref(null),
      matriz: ref(null),
    }
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      setTimeout(() => this.isLoading = false, 1000)
    },
    register() {

      this.error = []

      let data = new FormData();

      data.append('razaoSocial', this.razaoSocial || '')
      data.append('nomeFantasia', this.nomeFantasia || '')
      data.append('cpfcnpj', this.cpfcnpj || '')
      data.append('insestadual', this.insestadual || '')
      data.append('insmunicipal', this.insmunicipal || '')
      data.append('telefone', this.telefone || '')
      data.append('celular', this.celular || '')
      data.append('end_cep', this.end_cep || '')
      data.append('end_logradouro', this.end_logradouro || '')
      data.append('end_numero', this.end_numero || '')
      data.append('end_complemento', this.end_complemento || '')
      data.append('end_cidade', this.end_cidade || '')
      data.append('end_bairro', this.end_bairro || '')
      data.append('end_uf', this.end_uf || '')
      data.append('status', this.status || '')
      data.append('matriz', this.matriz || '')

      axios.post(this.url_api + '/api/company', {
        razaoSocial: this.razaoSocial,
        nomeFantasia: this.nomeFantasia,
        cpfcnpj: this.cpfcnpj,
        insestadual: this.insestadual,
        insmunicipal: this.insmunicipal,
        telefone: this.telefone,
        celular: this.celular,
        end_cep: this.end_cep,
        end_logradouro: this.end_logradouro,
        end_numero: this.end_numero,
        end_complemento: this.end_complemento,
        end_cidade: this.end_cidade,
        end_bairro: this.end_bairro,
        end_uf: this.end_uf,
        status: this.status,
        matriz: this.matriz

      })
        .then(() => {
          this.$snotify.success('Salvo com sucesso !');
        })
        .catch((error) => {
          // Handle any errors
          // console.error('Error /api/company:', error);
          this.errors = error.response.data.errors;
          this.$snotify.error('Verificar digitação !');
          return error;
        });
    },
    onPageChange() {
      this.register();
    }

  },
  mounted() {

  }
};
</script>


