<template>
    <router-link class="
            float-right
            bg-transparent
            text-gray-900
            font-semibold
            hover:text-gray-500
            py-2
            px-4
            border
            hover:border-transparent
            rounded
        " :class="[color ? 'hover:bg-' + color + '-500 border-' + color + '-500' : '']" :to="url">
        <fas :icon="[tipo, icone]" />
        {{ btnText }}

    </router-link>
</template>

<script setup>
import { defineProps, toRefs } from 'vue'

const props = defineProps({
    btnText: String,
    url: { type: String, default: '' },
    color: String,
    tipo: String,
    icone: String,
})
const { btnText, url, color } = toRefs(props)
</script>

