<template>
    <div>
        <button @click="open = !open" class="
                bg-transparent
                hover:bg-green-400
                text-white
                font-semibold
                hover:text-white
                py-2
                py-4
                hover:border-transparent
                rounded-[12px]
                border-double border-4 border-sky-500
                outline outline-offset-2 outline-4
                shadow-xl
            ">
            <fas :icon="['fas', 'bars']" style="font-size:36px" />
        </button>
        <div v-show="open" class="
                animated
                slideInDown
                faster
                fixed
                w-full
                h-full
                top-0
                left-0
                flex
                justify-center
                backdrop-blur-sm
            ">
            <div class="bg-black absolute w-full h-full opacity-75"></div>
            <div class="my-auto fixed border-white w-80 pt-16">
                <p class="text-2xl text-center text-white font-bold">Menu</p>
                <RouterLinkButton @click="open = !open" class="w-full text-white text-center text-lg"
                    btnText="Painel de controle" color="green" url="/dashboard" />
                <div v-if="userStore.administrador == 1">
                    <RouterLinkButton @click="open = !open" class="w-full text-white text-center text-lg" btnText="Admin"
                        color="green" url="/administracao" />
                </div>
                <RouterLinkButton @click="open = !open" class="w-full text-white text-center text-lg" btnText="Solicitação"
                    color="green" url="/solicitacao" />
                <RouterLinkButton @click="open = !open" class="w-full text-white text-center text-lg" btnText="Treinamento"
                    color="green" url="/admlistatreinamento" />
                <RouterLinkButton @click="open = !open" class="w-full text-white text-center text-lg" btnText="Relatórios"
                    color="green" url="/relatorio" />
                <RouterLinkButton @click="logout" class="w-full text-white text-center text-lg mt-4" btnText="Saír"
                    color="red" />
                <RouterLinkButton @click="open = !open" class="w-full text-white text-center text-lg mt-4"
                    btnText="Retornar" color="red" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios';
import { useUserStore } from '@/store/user-store';
import { useRouter } from 'vue-router';
import RouterLinkButton from '../global/RouterLinkButton.vue';

const router = useRouter()
const userStore = useUserStore()
const url = process.env.VUE_APP_API_URL;

let url_api = url
let open = ref(false)

const logout = async () => {
    try {

        let res = await axios.post(url_api + '/api/logout', {
            user_id: userStore.id
        })

        // console.log(res.data)
        localStorage.clear();
        router.push('/')

    } catch (err) {
        console.log(err)
        router.push('/')
    }
}

</script>
