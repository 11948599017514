<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div class="
              container 
              mx-auto 
              my-12
              h-auto
              flex 
              flex-wrap 
              justify-between 
              items-start 
              text-sm 
              p-8 
              pt-8 
              pb-4 
              box-border 
              border-4 
              border-gray-400 
              bg-gray-100">
            <a class="text-2xl text-center text-black font-bold">Solicitação : Cadastro de Entrada(Compra) </a>
            <RouterLinkButton btnText="" color="sky" tipo="fas" icone="arrow-left" url='/solicitacao' />
            <div class="bg-green-500 w-full h-1"></div>
            <TextInput label="" placeholder="" v-model:input="id_cliente" inputType="hidden" readonly />
            <a class="text-sky-400 font-bold">Empresa : {{ id_cliente }}-{{ razaoSocial }}</a>
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" v-on:click="toggleModal()">
                Escolha a Empresa que pertence a entrada !
              </button>
              <div v-if="showModal"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 class="text-3xl font-semibold">
                        Empresa
                      </h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModal()">
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput label="Digitar o nome da Empresa" placeholder="Pesquisar..."
                            v-model:input="searchCompany" inputType="text" />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form @submit.prevent="onSubmit" @click="getCompanyList">
                            <LoadingButton :isLoading="isLoading" class="
                              block w-full
                              bg-green-500
                              text-white
                              rounded-sm
                              py-3 text-sm
                              tracking-wide
                              transform 
                              motion-safe:hover:-translate-y-1 
                              text-blue-700 
                              motion-safe:hover:scale-110 
                              transition 
                              ease-in-out">
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Empresa</th>
                                <th class="border px-4 py-2"></th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="text-gray-500 bg-gray-100" v-for="listCompany in listCompanies"
                                :key="listCompany">
                                <td>{{ listCompany.id }}</td>
                                <td>{{ listCompany.razaoSocial }}</td>
                                <td>{{ listCompany.nomeFantasia }}</td>
                                <td>
                                  <div class="
                                    block w-full
                                    bg-green-500
                                    text-white
                                    rounded-sm
                                    py-3 text-sm
                                    tracking-wide
                                    transform 
                                    motion-safe:hover:-translate-y-1 
                                    text-blue-700 
                                    motion-safe:hover:scale-110 
                                    transition 
                                    ease-in-out">
                                    <SubmitFormButton btnText="Escolha" @click="selectCompany(listCompany)" />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2 ">
                            <v-pagination v-model="pageCompanies" :pages="pageCountCompanies" :range-size="1"
                              active-color="#337aff" @update:modelValue="listCompanies" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button" v-on:click="toggleModal()">
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <TextInput label="" placeholder="" v-model:input="id_fornecedor" inputType="hidden" readonly />
            <a class="text-sky-400 font-bold">Fornecedor : {{ id_fornecedor }}-{{ nome_fornecedor }}</a>
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button" v-on:click="toggleModalSupplier()">
                Escolha o fornecedor que pertence a entrada !
              </button>
              <div v-if="showModalSupplier"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <!--header-->
                    <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h3 class="text-3xl font-semibold">
                        Fornecedor
                      </h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModalSupplier()">
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput label="Digitar o nome do fornecedor" placeholder="Pesquisar..."
                            v-model:input="searchSupplier" inputType="text" />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form @submit.prevent="onSubmit" @click="getSupplierList">
                            <LoadingButton :isLoading="isLoading" class="
                              block w-full
                              bg-green-500
                              text-white
                              rounded-sm
                              py-3 text-sm
                              tracking-wide
                              transform 
                              motion-safe:hover:-translate-y-1 
                              text-blue-700 
                              motion-safe:hover:scale-110 
                              transition 
                              ease-in-out">
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Fornecedor</th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="text-gray-500 bg-gray-100" v-for="listSupplier in listSuppliers"
                                :key="listSupplier">
                                <td>{{ listSupplier.id }}</td>
                                <td>{{ listSupplier.razaoSocial }}</td>
                                <td>
                                  <div class="
                                    block w-full
                                    bg-green-500
                                    text-white
                                    rounded-sm
                                    py-3 text-sm
                                    tracking-wide
                                    transform 
                                    motion-safe:hover:-translate-y-1 
                                    text-blue-700 
                                    motion-safe:hover:scale-110 
                                    transition 
                                    ease-in-out">
                                    <SubmitFormButton btnText="Escolha" @click="selectSupplier(listSupplier)" />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2 ">
                            <v-pagination v-model="pageSuppliers" :pages="pageCountSuppliers" :range-size="1"
                              active-color="#337aff" @update:modelValue="listSuppliers" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        class="text-red-500 bg-transparent border border-solid border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button" v-on:click="toggleModalSupplier()">
                        Sair
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="showModalSupplier" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="w-full md:w-full px-3">
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none">
                  <div class=" w-full text-gray-900 md:w-full px-6">
                    <TextInputDate label="Data emissão" placeholder="Digitar emissão !" v-model:input="dt_emissao"
                      inputType="text" />
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="flex flex-wrap mt-8 mb-6">
              <form @submit.prevent="onSubmit" @click="register">
                <LoadingButton :isLoading="isLoading" class="
                  block w-full
                  bg-green-500
                  text-white
                  rounded-sm
                  py-3 text-sm
                  tracking-wide
                  transform 
                  motion-safe:hover:-translate-y-1 
                  text-blue-700 
                  motion-safe:hover:scale-110 
                  transition 
                  ease-in-out">
                  Salvar
                </LoadingButton>
              </form>
              <vue-snotify />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { ref } from 'vue';
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import LoadingButton from '@/components/global/LoadingButton.vue';
import SubmitFormButton from '@/components/global/SubmitFormButton.vue';

import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '@/store/user-store';

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    LoadingButton,
    TextInput,
    SubmitFormButton,
    TextInputDate

  },
  name: "regular-modal",
  data() {

    const userStore = useUserStore();
    const route = useRoute()
    const router = useRouter()

    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);
    let showModal = ref(false);
    let showModalSupplier = ref(false);

    let searchCompany = ref(null)
    let listCompanies = ref(null)
    let pageCompanies = ref(1)
    let pageCountCompanies = ref(null)

    let errors = ref([])

    return {
      date: new Date().getFullYear(),
      useradministrador: userStore.administrador,
      userid_company: userStore.id_empresa,
      id_responsavel: userStore.id,
      name_responsavel: userStore.full_name,
      route: route,
      router: router,
      url_api: url,
      hover: hover,
      showModal: showModal,
      showModalSupplier: showModalSupplier,
      searchCompany: searchCompany,
      listCompanies: listCompanies,
      pageCompanies: pageCompanies,
      pageCountCompanies: pageCountCompanies,
      errors: errors,
      error: errors,
      searchSupplier: ref(null),
      listSuppliers: ref(null),
      pageSuppliers: ref(1),
      pageCountSuppliers: ref(null),
      id_empresa: ref(null),
      isLoading: ref(false),
      id_cliente: ref(null),
      razaoSocial: ref(null),
      id_fornecedor: ref(null),
      nome_fornecedor: ref(null),
      dt_emissao: ref(null)
    }
  },
  methods: {
    getCompanyList() {
      // Make a GET request to the API
      axios.get(this.url_api + '/api/companysearch/' + this.searchCompany + '?page=' + this.pageCompanies)
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountCompanies = response.data.page_count1
          this.listCompanies = response.data.paginate1.data
        })
        .catch((error) => {
          // Handle any errors
          console.error('Error fetching data:', error);
          this.$snotify.error('Verifique novamente !');
        });
    },
    getSupplierList() {

      axios.get(this.url_api + '/api/suppliersearch/' + this.searchSupplier + '?page=' + this.pageSuppliers)
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountSuppliers = response.data.page_count1
          this.listSuppliers = response.data.paginate1.data

        })
        .catch((error) => {
          // Handle any errors
          console.error('Error fetching data:', error);
          this.$snotify.error('Verifique novamente !');
        });

    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleModalSupplier() {
      this.showModalSupplier = !this.showModalSupplier;
    },
    selectCompany(event) {
      this.id_cliente = event.id
      this.razaoSocial = event.razaoSocial

      if (this.userid_company !== this.id_cliente) {
        this.$snotify.error('Empresa não pertence a : ' + this.razaoSocial);
        this.id_cliente = null
        this.razaoSocial = null
      } else {

        this.$snotify.warning('Escolheu a : ' + this.razaoSocial);

      }
    },
    selectSupplier(event) {
      this.id_fornecedor = event.id
      this.nome_fornecedor = event.razaoSocial
      this.$snotify.warning('Escolheu a : ' + this.nome_fornecedor);
    },
    onSubmit() {
      this.isLoading = true;
      setTimeout(() => this.isLoading = false, 1000)
    },
    register() {
      // console.log('Dentro register')

      this.error = []
      let data = new FormData();
      data.append('id_responsavel', this.id_responsavel || '')
      data.append('id_cliente', this.id_cliente || '')
      data.append('id_fornecedor', this.id_fornecedor || '')
      data.append('dt_emissao', this.dt_emissao || '')

      axios.post(this.url_api + '/api/salesorderentry', {
        id_responsavel: this.id_responsavel,
        id_cliente: this.id_cliente,
        id_fornecedor: this.id_fornecedor,
        dt_emissao: this.dt_emissao
      })
        .then((teste) => {
          console.log(teste)
          this.$snotify.success('Salvo com sucesso !');
        })
        .catch((error) => {
          // Handle any errors
          // this.errors = error.data.error;
          console.error('Error salesorderentry:', this.error);
          this.$snotify.error('Verificar Empresa, Fornecedor ou data de emissão!');
          return error;
        });
    },
    onPageChange() {
      this.getCompanyList();
      this.getSupplierList();
      this.register();
    }

  },
  mounted() {

  }
};
</script>

