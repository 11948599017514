<template>
    <div>
        <sidebar-component></sidebar-component>
        <div class="relative md:ml-64 bg-blueGray-100">
            <navbar-component></navbar-component>
            <vue-snotify />
            <!-- Header -->
            <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
                <div class="px-4 md:px-10 mx-auto w-full">
                    <div>
                        <!-- Card stats -->
                        <div v-if="administrador == 1" class="flex flex-wrap">
                            <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
                                <div
                                    class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                                    <div class="flex-auto p-4">
                                        <div class="flex flex-wrap">
                                            <h5 class="text-blueGray-400 uppercase font-bold text-xs">
                                                ADMINISTRAÇÃO
                                            </h5>
                                            <p
                                                class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-slate-700">
                                                <span class="whitespace-nowrap">
                                                    COLABORADORES
                                                    <span class="font-semibold text-xl text-blueGray-700">
                                                        {{ parseInt(adm_qtduser).toLocaleString() }}
                                                    </span>
                                                </span>
                                            </p>
                                            <p
                                                class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-slate-700">
                                                <span class="whitespace-nowrap">
                                                    MATRIZ
                                                    <span class="font-semibold text-xl text-blueGray-700">
                                                        {{ parseInt(adm_qtdmatriz).toLocaleString() }}
                                                    </span>
                                                </span>
                                            </p>
                                            <p
                                                class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-slate-700">
                                                <span class="whitespace-nowrap">
                                                    FILIAIS
                                                    <span class="font-semibold text-xl text-blueGray-700">
                                                        {{ parseInt(adm_qtdfilial).toLocaleString() }}
                                                    </span>
                                                </span>
                                            </p>

                                            <div class="relative w-auto pl-4 flex-initial">
                                                <div
                                                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-sky-500">
                                                    <i class="far fa-chart-bar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
                                <div
                                    class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                                    <div class="flex-auto p-4">
                                        <div class="flex flex-wrap">
                                            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                                                <h5 class="text-blueGray-400 uppercase font-bold text-xs">
                                                    ADMINISTRAÇÃO
                                                </h5>
                                                <p
                                                    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-slate-700">
                                                    <span class="whitespace-nowrap">
                                                        ESTOQUE
                                                        <span class="font-semibold text-xl text-blueGray-700">
                                                            {{ parseInt(adm_qtdstock).toLocaleString() }}
                                                        </span>
                                                    </span>
                                                </p>
                                                <p
                                                    class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent  text-slate-700">
                                                    <span class="whitespace-nowrap">
                                                        ESTINTOR
                                                        <span class="font-semibold text-xl text-blueGray-700">
                                                            {{ parseInt(adm_qtdextintor).toLocaleString() }}
                                                        </span>
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="relative w-auto pl-4 flex-initial">
                                                <div
                                                    class="text-white items-center px-6 py-6 border-0 rounded-full relative mb-4 bg-red-500">
                                                    <span @mouseover="hover = true" @mouseleave="hover = false">
                                                        <fas :icon="['fas', 'bell']" />
                                                    </span>
                                                    <span v-if="hover" class="inline-block align-middle mr-8">
                                                        <p class="capitalize">Quantidade estoque > 0</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
                                <div
                                    class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
                                    <div class="flex-auto p-4">
                                        <div class="flex flex-wrap">
                                            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                                                <h5 class="text-blueGray-400 uppercase font-bold text-xs">
                                                    ADMINISTRAÇÃO
                                                </h5>
                                                <p class="text-sm text-blueGray-400 mt-4">
                                                    <span class="whitespace-nowrap">
                                                        FORNECEDOR
                                                    </span>
                                                </p>
                                                <span class="font-semibold text-xl text-blueGray-700">
                                                    {{ parseInt(adm_qtdtraining).toLocaleString() }}
                                                </span>
                                            </div>
                                            <div class="relative w-auto pl-4 flex-initial">
                                                <div
                                                    class="text-white items-center px-6 py-6 border-0 rounded-full relative mb-4 bg-green-500">
                                                    <span @mouseover="hover = true" @mouseleave="hover = false">
                                                        <fas :icon="['fas', 'bell']" />
                                                    </span>
                                                    <span v-if="hover" class="inline-block align-middle mr-8">
                                                        <p class="capitalize">Status : Aprovado</p>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-4 md:px-10 mx-auto w-full -m-24">
                <div class="flex flex-wrap mt-4">
                    <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
                        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
                            <div class="rounded-t mb-0 px-4 py-3 border-0">
                                <div class="flex flex-wrap items-center">
                                    <div class="relative w-full px-4 max-w-full flex-grow flex-1">
                                        <h3 class="font-semibold text-base text-blueGray-700">
                                            EMPRESAS
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="block w-full overflow-x-auto">
                                <table class="items-center w-full bg-transparent ">
                                    <thead>
                                        <tr class="bg-gray-300">
                                            <th
                                                class="px-6 bg-blueGray-50 text-blueGray-700 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                EMPRESA
                                            </th>
                                            <th
                                                class="px-6 bg-blueGray-50 text-blueGray-700 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                COLABORADOR
                                            </th>
                                            <th
                                                class="px-6 bg-blueGray-50 text-blueGray-700 align-middle border border-solid border-green-700 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                                                ESTINTOR
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="listStatisc in listStatiscs" :key="listStatisc.id">
                                            <td
                                                class="border border-solid border-indigo-500/100 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                                                {{ listStatisc.company.nomeFantasia }}
                                            </td>
                                            <td
                                                class="border border-solid border-indigo-500/100 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {{ parseInt(listStatisc.qtd_users).toLocaleString() }}
                                            </td>
                                            <td
                                                class="border border-solid border-indigo-500/100 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                {{ parseInt(listStatisc.qtd_extintor).toLocaleString() }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { ref } from 'vue';
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import { useUserStore } from '@/store/user-store';

export default {
    components: {
        NavbarComponent,
        SidebarComponent,
        AdmMenuComponent
    },

    data() {

        const userStore = useUserStore();
        const url = process.env.VUE_APP_API_URL;
        let listStatiscs = ref([]);
        let adm_qtduser = ref(0);
        let adm_qtdstock = ref(0);
        let adm_qtdmatriz = ref(0);
        let adm_qtdfilial = ref(0);
        let adm_qtdextintor = ref(0);
        let adm_qtdepi = ref(0);
        let adm_qtdtraining = ref(0);
        let hover = ref(false);

        return {
            date: new Date().getFullYear(),
            administrador: userStore.administrador,
            url_api: url,
            listStatiscs: listStatiscs,
            adm_qtduser: adm_qtduser,
            adm_qtdstock: adm_qtdstock,
            adm_qtdmatriz: adm_qtdmatriz,
            adm_qtdfilial: adm_qtdfilial,
            adm_qtdextintor: adm_qtdextintor,
            adm_qtdepi: adm_qtdepi,
            adm_qtdtraining: adm_qtdtraining,
            hover: hover
        }
    },
    mounted() {

        this.$snotify.info('Aguarde, atualizando ... !');
        // Make a GET request to the API
        axios.get(this.url_api + '/api/Statistic')
            .then((response) => {
                // Handle the successful response
                // console.log(response.data);
                this.listStatiscs = response.data.statisc;
                this.adm_qtduser = response.data.adm_qtdUsuario;
                this.adm_qtdstock = response.data.adm_qtdStock;
                this.adm_qtdextintor = response.data.adm_qtdExtintor;
                this.adm_qtdepi = response.data.adm_qtdEPI;
                this.adm_qtdtraining = response.data.adm_qtdTraining;
                this.adm_qtdmatriz = response.data.adm_qtdMatriz;
                this.adm_qtdfilial = response.data.adm_qtdFilial;
                // console.log(this.adm_qtduser);
            })
            .catch((error) => {
                // Handle any errors
                console.error('Error fetching data:', error);
                this.$snotify.error('Verificar !');
                return error;
            });

    },

};
</script>

