import axios from "axios";
import { defineStore } from "pinia";

const url = process.env.VUE_APP_API_URL;

let url_api = url;

export const useCompaniesStore = defineStore("company", {
  state: () => ({
    id: null,
    id_responsavel: null,
    razaoSocial: null,
    nomeFantasia: null,
    cpfcnpj: null,
    insestadual: null,
    insmunicipal: null,
    telefone: null,
    celular: null,
    end_cep: null,
    end_logradouro: null,
    end_complemento: null,
    end_numero: null,
    end_cidade: null,
    end_bairro: null,
    end_uf: null,
    status: null,
    matriz: null,
    deleted_at: null,
  }),
  actions: {
    setCompanyIdDetails(res) {
      //Busca do banco de dados
      // console.log("setCompanyIdDetails", res.data);
      this.$state.id = res.data.id;
      this.$state.id_responsavel = res.data.id_responsavel;
      this.$state.razaoSocial = res.data.razaoSocial;
      this.$state.nomeFantasia = res.data.nomeFantasia;
      this.$state.cpfcnpj = res.data.cpfcnpj;
      this.$state.insestadual = res.data.insestadual;
      this.$state.insmunicipal = res.data.insmunicipal;
      this.$state.telefone = res.data.telefone;
      this.$state.end_cep = res.data.end_cep;
      this.$state.end_logradouro = res.data.end_logradouro;
      this.$state.end_complemento = res.data.end_complemento;
      this.$state.end_numero = res.data.end_numero;
      this.$state.end_cidade = res.data.end_cidade;
      this.$state.end_bairro = res.data.end_bairro;
      this.$state.end_uf = res.data.end_uf;
      this.$state.status = res.data.status;
      this.$state.matriz = res.data.matriz;
      this.$state.deleted_at = res.data.deleted_at;
    },

    async fetchByIdCompany(id) {
      let res = await axios.get(url_api + "/api/company/" + id);
      // console.log(res.data);
      this.$state.id = res.data.company.id;
      this.$state.id_responsavel = res.data.company.id_responsavel;
      this.$state.razaoSocial = res.data.company.razaoSocial;
      this.$state.nomeFantasia = res.data.company.nomeFantasia;
      this.$state.cpfcnpj = res.data.company.cpfcnpj;
      this.$state.insestadual = res.data.company.insestadual;
      this.$state.insmunicipal = res.data.company.insmunicipal;
      this.$state.telefone = res.data.company.telefone;
      this.$state.celular = res.data.company.celular;
      this.$state.end_cep = res.data.company.end_cep;
      this.$state.end_logradouro = res.data.company.end_logradouro;
      this.$state.end_complemento = res.data.company.end_complemento;
      this.$state.end_numero = res.data.company.end_numero;
      this.$state.end_cidade = res.data.company.end_cidade;
      this.$state.end_bairro = res.data.company.end_bairro;
      this.$state.end_uf = res.data.company.end_uf;
      this.$state.status = res.data.company.status;
      this.$state.matriz = res.data.company.matriz;
      this.$state.deleted_at = res.data.company.deleted_at;
    },

    clearIdCompany() {
      this.$state.id = null;
      this.$state.id_responsavel = null;
      this.$state.razaoSocial = null;
      this.$state.nomeFantasia = null;
      this.$state.cpfcnpj = null;
      this.$state.insestadual = null;
      this.$state.insmunicipal = null;
      this.$state.telefone = null;
      this.$state.celular = null;
      this.$state.end_cep = null;
      this.$state.end_logradouro = null;
      this.$state.end_complemento = null;
      this.$state.end_numero = null;
      this.$state.end_cidade = null;
      this.$state.end_bairro = null;
      this.$state.end_uf = null;
      this.$state.status = null;
      this.$state.matriz = null;
      this.$state.deleted_at = null;
    },
  },
  persist: true,
});
