<template>
  <div>
    <main class="profile-page">
      <section class="relative block" style="height: 500px">
        <div class="absolute top-10 w-full h-full bg-center bg-cover">
          <img src="@/assets/img/logoempresa.jpg" />
          <!-- <span id="blackOverlay" class="w-full h-full absolute opacity-50 bg-black"></span> -->
        </div>
      </section>
      <section class="relative py-16 bg-gray-300">
        <div class="container mx-auto px-4">
          <div
            class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64"
          >
            <div class="px-6">
              <div class="flex flex-wrap justify-center">
                <div class="text-center mt-12">
                  <h3
                    class="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2"
                  >
                    SGA - SISTEMA DE GESTÃO DE ADMINISTRATIVA
                  </h3>
                  <div class="flex flex-wrap justify-center">
                    <div class="py-6 px-3 mt-32 sm:mt-0">
                      <router-link
                        to="login"
                        class="w-full mr-1 py-2 px-4 bg-blue-600 text-white font-semibold text-2xl rounded-lg shadow-md hover:bg-green-500 foucus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 cursor-pointer transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                      >
                        <i class="fas fa-sign-out-alt"></i>
                        Login</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-10 py-10 border-t border-gray-300 text-center">
                <div class="flex flex-wrap justify-center">
                  <div class="w-full lg:w-9/12 px-4">
                    <p class="mb-4 text-lg leading-relaxed text-gray-800">
                      Projetado para planejar e controlar estoque, compras,
                      vendas, financeiro e administrativo !
                    </p>
                    <p class="mb-4 text-lg leading-relaxed text-gray-800">
                      Ao se cadastrar no sistema o usuário autoriza que colete e
                      utilize seus dados com a finalidade de cadastro interno
                      conforme a LGPD - Lei Geral de Proteção de Dados
                    </p>
                  </div>
                </div>
              </div>
              <hr class="mt-6 border-b-1 border-gray-400" />
              <div class="text-center mt-12">
                <h3
                  class="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2"
                >
                  Entrar em contato com suporte.
                </h3>
                <div class="flex flex-wrap justify-center">
                  <div class="py-6 px-3 mt-32 sm:mt-0">
                    <!-- Whatsapp -->
                    <button
                      type="button"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      class="mb-2 inline-block rounded px-6 py-2.5 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                      style="background-color: #128c7e"
                    >
                      <a href="https://wa.me/5511962422624">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
                          />
                        </svg>
                      </a>
                    </button>
                  </div>
                </div>
              </div>
              <hr class="mt-6 border-b-1 border-gray-400" />
              <div class="text-center mt-12">
                <h3
                  class="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2"
                >
                  Entrar em contato com CHAT.
                </h3>
                <div class="flex flex-wrap justify-center">
                  <div class="py-6 px-3 mt-32 sm:mt-0">
                    <div class="py-6 px-3 mt-32 sm:mt-0">
                      <router-link
                        to="admchat"
                        class="w-full mr-1 py-2 px-4 bg-blue-600 text-white font-semibold text-2xl rounded-lg shadow-md hover:bg-green-500 foucus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 cursor-pointer transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                      >
                        <i class="fas fa-sign-out-alt"></i>
                        Chat</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component></footer-component>
  </div>
</template>
<script>
import FooterComponent from "@/components/global/login/LoginFooter.vue";
export default {
  name: "profile-page",
  components: {
    FooterComponent,
  },
};
</script>
